import { auth, httpCallables } from "../firebase";
import { useDispatch, useSelector } from "react-redux";
import { setAuth, setOriginalUser, setProfile } from "../redux/userSlice";
import { useHistory, useLocation } from "react-router-dom";

function useImpersonate() {
  const dispatch = useDispatch();
  const history = useHistory();
  let { pathname, search } = useLocation();

  const userProfile = useSelector((state) => state.user.userProfile);
  const originalAuth = useSelector((state) => state.user.original_auth);

  function impersonate(user) {
    dispatch(setProfile({ ...userProfile, selectedCourse: false }));

    httpCallables.impersonateUser({ uid: user }).then((response) => {
      if (response.data && response.data.length) {
        //Save the url to get back to once stoping the impersonate
        localStorage.setItem(
          "impersonate.getBackToUrl",
          `${pathname}/${search}`
        );

        auth.currentUser.getIdTokenResult(true).then((idTokenResult) => {
          dispatch(setOriginalUser(idTokenResult.token));
          auth.signInWithCustomToken(response.data).then(() => {
            history.push("/");
          });
        });
      }
    });
  }

  function stopImpersonate() {
    httpCallables
      .stopImpersonatingUser({ token: originalAuth })
      .then((response) => {
        auth.signInWithCustomToken(response.data).then((userCredential) => {
          let user = userCredential.user;
          dispatch(setOriginalUser(-1));
          dispatch(
            setAuth({
              displayName: user.displayName,
              photoURL: user.photoURL,
              uid: user.uid,
              email: user.email,
              isAdmin: true
            })
          );

          const url = localStorage.getItem("impersonate.getBackToUrl");
          history.push(url || `/admin`);
        });
      });
  }

  return { impersonate, stopImpersonate };
}

export default useImpersonate;
