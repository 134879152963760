// Dependencies
import React, { useEffect, useState } from "react";
import { firebaseFunctions } from "../../../firebase";
import { useIntl } from "react-intl";
import { captureException } from "../../../utils/errorHandlers";
import { useQuery } from "../../../hooks";

// Redux Dependencies
import { useDispatch } from "react-redux";
import { setBreadcrumbs } from "../../../redux/readerActionsSlice";

// Components
import TasksListItem from "./TasksListItem";

import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";

// Styles
const useStyles = makeStyles((theme) => ({
  taskContainer: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    marginBlockStart: theme.spacing(6),
    marginBlockEnd: theme.spacing(3)
  }
}));

export default function TasksAdminView() {
  // Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { task_id } = useQuery();

  // Redux State
  // const sentFeedbackTask = useSelector((state) => state.task.sentFeedbackTask);

  // Ephemeral State
  const [submissions, setSubmissions] = useState([]);
  const [course, setCourse] = useState(false);
  const [courses, setCourses] = useState(false);

  // Behavior
  useEffect(() => {
    var getMyCourses = firebaseFunctions.httpsCallable("courses-getMyCourses");
    getMyCourses().then((response) => {
      setCourses(response.data);
    });
  }, []);

  useEffect(() => {
    //let unsubscribe = false;
    var getAllSubmissions = firebaseFunctions.httpsCallable(
      "adminFunctions-getTaskSubmissions"
    );
    getAllSubmissions({ task: task_id })
      .then((response) => {
        setSubmissions(response.data);
      })
      .catch((err) => captureException(err));
  }, [task_id]);

  useEffect(() => {
    if (course && submissions && submissions.length) {
      let task = submissions[0];
      let parts = [];
      parts.push({
        url: "/profile",
        resetCourse: true,
        text: intl.formatMessage({
          id: "appBar.tasks",
          defaultMessage: "Assignments"
        })
      });
      task &&
        task.course_name &&
        parts.push({ url: "/profile", text: task.course_name, course: course });
      task && parts.push({ text: task.name });
      dispatch(setBreadcrumbs({ breadcrumbs: parts, blue: true }));
    }
  }, [submissions, course, dispatch, intl]);

  useEffect(() => {
    if (submissions && courses && submissions.length && courses.length) {
      let filteredCourses = courses.filter(
        (el) => el.id === submissions[0].course_id
      );
      if (filteredCourses && filteredCourses.length) {
        setCourse(filteredCourses[0]);
      }
    }
  }, [submissions, courses]);

  // Render
  return (
    <Box className={classes.taskContainer} key={task_id}>
      {course && submissions && submissions.length && (
        <TasksListItem
          course={course}
          openTasks={[submissions[0].task_id]}
          toggleTask={false}
          task={{
            ...submissions[0],
            answers: submissions[0].task_answers,
            id: submissions[0].task_id
          }}
          submissions={submissions}
          singleItemView={true}
        />
      )}
    </Box>
  );
}
