// TODO: burn this component with fire and replace it with something that makes sense and is not a mess of code and logic and state and redux and hooks and whatnot

// Dependencies
import React, { useRef, useState, useEffect, useCallback } from "react";
import { firebaseApp, httpCallables } from "../../../firebase";

import { FormattedMessage, useIntl } from "react-intl";
import EpubView from "../../reader/EpubView/EpubView";
import { useRendition } from "../../../RenditionContext";
import ePub from "epubjs";
import PdfCFI from "../../../utils/pdf-cfi";
import { v4 as uuid } from "uuid";
import { isEmpty } from "lodash";
import { captureException } from "../../../utils/errorHandlers";
import {
  combinePartialCfisToCfi,
  removeAllAnotationsOfType
} from "../../reader/utils";
import {
  ANNOTATION_TYPES,
  INTERACTION_TYPES,
  USER_TYPE
} from "../../../consts";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentText, isPdfSelector } from "../../../redux/textsSlice";

import { setClickedMatch } from "../../../redux/taskSlice";
import { selectSubmission } from "../../../redux/tasksSlice";
import {
  selectTeacherQuestionFeedback,
  questionHighlights,
  selectAnswerComment,
  createInteraction,
  updateInteraction,
  selectMentorQuestionFeedback
} from "../../../redux/interactionsSlice";
import { closeAnnotatorBar } from "../../../redux/highlightSlice";
import { selectZoom } from "../../../redux/pdfSlice";

// Components
import PdfView from "../../reader/pdf/PdfView";
import { getHighlightColor } from "../../../utils/colors";
import makeStyles from "@mui/styles/makeStyles";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  InputAdornment,
  Card,
  Typography,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Tooltip,
  Paper,
  Button,
  IconButton
} from "@mui/material";
import { generateRandomKey, getWordCount } from "./utils";
import { useQuery } from "../../../hooks";
import {
  selectDarkMode,
  selectTextDirection
} from "../../../redux/firestoreSelectors";
import { useCurrentInteractionWithPoints } from "../../../hooks/useCurrentInteractionWithPoints";

const POSITIONS = { START: "start", END: "end" };
const TYPES = { STUDENT: "student", TEACHER: "teacher" };
const MARKS_TYPES_ARRAY = [TYPES.STUDENT, TYPES.TEACHER];
const colors = { match: "#91fcc2", teacher: "#04E3FD", student: "#FFFF00" };

//Styles
const useStyles = makeStyles((theme) => ({
  pointsContainer: {
    padding: "16px",
    position: "relative",
    minHeight: "64px"
  },
  feedbackBtns: {
    position: "absolute",
    right: "8px"
  },
  dialogActions: {
    justifyContent: "left"
  },
  dialog: {
    zIndex: 10
  },
  dialogActionsRtl: {
    justifyContent: "right"
  },
  dialogBtn: {
    position: "absolute",
    color: "#ffffff",
    right: "16px"
  },

  dialogTitle: {
    background: "#168FEE",
    display: "inline-flex",
    color: "#ffffff"
  },
  points: {
    display: "inline-block",
    border: "1px solid #168fee",
    borderRadius: "4px",
    paddingLeft: "8px",
    paddingRight: "8px",
    width: "64px",
    "& input": {
      textAlign: "center",
      color: theme.palette.text.disabled
    },
    "& input[type='number']::-webkit-inner-spin-button, & input[type='number']::-webkit-outer-spin-button":
      {
        "-webkit-appearance": "none",
        margin: 0,
        display: "none"
      }
  },
  hidden: {
    visibility: "hidden",
    position: "absolute",
    fontSize: "20px",
    maxWidth: "95%"
  },
  epubViewContainer: {
    position: "relative"
  },
  left: {
    textAlign: "left"
  },
  pointsMsg: {
    color: "#168fee",
    display: "inline-block",
    lineHeight: "34px"
  },
  eval: {
    color: "#168fee",
    fontSize: "20px",
    lineHeight: "32px",
    "& ::placeholder": {
      /* Chrome, Firefox, Opera, Safari 10.1+ */ color: "#168fee",
      opacity: 1
    },
    "& button": {
      visibility: "hidden"
    },
    "&:hover button": {
      visibility: "visible"
    }
  },

  adornment: {
    color: "#168fee"
  },
  colorsReader: {
    display: "flex",
    zIndex: "2",
    color: theme.palette.text.primary
  },
  matchColor: {
    marginBottom: "8px",
    marginLeft: "8px",
    marginRight: "8px"
  },
  textIcon: {
    padding: "4px"
  },
  studentColor: {
    marginBottom: "8px",
    marginLeft: "8px",
    marginRight: "8px"
  },
  yourColor: {
    marginBottom: "8px",
    marginLeft: "8px",
    marginRight: "8px"
  },
  feedbackCard: {
    fontSize: "16px"
  },
  feedbackBody: {
    padding: "16px",
    position: "relative"
  },
  feedbackHeader: {
    color: "#168fee",
    fontSize: "20px",
    lineHeight: "32px",
    padding: "16px"
  }
}));

function useDebounce(value, delay = 500) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export default function FeedbackQuestion({ index, task, showMatch }) {
  const EpubCFI = new ePub.CFI();
  const PdfCfi = new PdfCFI();

  //Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const epubElemRef = useRef();
  const isInitialLoadRef = useRef(true);
  const prevIndexdRef = useRef(index);
  const { submission_id } = useQuery();

  //Redux state
  const text = useSelector(selectCurrentText);
  const fontSizeValue = useSelector((state) => state.user.userProfile.fontSize);
  const fontSize = useSelector((state) => state.user.fontSizeOptions);
  const clickedMatch = useSelector((state) => state.task.clickedMatch);
  const darkMode = useSelector((state) => selectDarkMode(state));
  const textDirection = useSelector((state) => selectTextDirection(state));
  const question = useCurrentInteractionWithPoints();
  const studentHighlights = useSelector((state) =>
    questionHighlights(state, question.id)
  );
  const studentAnswer = useSelector((state) =>
    selectAnswerComment(state, question.id)
  );
  const isPdf = useSelector(isPdfSelector);
  const zoom = useSelector(selectZoom);
  const mentorFeedback = useSelector((state) =>
    Object.keys(question).length > 0
      ? selectMentorQuestionFeedback(state, question.id)
      : null
  );
  const teacherFeedback = useSelector((state) =>
    Object.keys(question).length > 0
      ? selectTeacherQuestionFeedback(state, question.id)
      : null
  );
  const submission = useSelector((state) =>
    selectSubmission(state, Number(submission_id))
  );

  // Ephemeral State
  const [openDialog, setOpenDialog] = useState(false);
  const [linksCfi, setLinksCfi] = useState([]);
  const { rendition } = useRendition();
  const [pdfHighlights, setPdfHighlights] = useState([]);
  const [validPointsPerQuestion, setValidPointsPerQuestion] = useState(true);
  const [isTitleEditing, setIsTitleEditing] = useState(false);
  const [feedback, setFeedback] = useState({
    title: "",
    content: "",
    points: question.calculatedPoints ?? 0
  });
  const [isManualUpdate, setIsManualUpdate] = useState(false);
  const debouncedFeedback = useDebounce(feedback);

  // Variables
  const CFIByFileType = isPdf ? PdfCfi : EpubCFI;
  const readOnly = submission.is_checked;

  useEffect(() => {
    if (
      teacherFeedback &&
      (index !== prevIndexdRef.current || isInitialLoadRef.current)
    ) {
      setIsManualUpdate(false);
      isInitialLoadRef.current = false;
      prevIndexdRef.current = index;
      const feedback = {
        title: teacherFeedback.title ?? "",
        content: teacherFeedback.content ?? "",
        rich_text: teacherFeedback.rich_text,
        word_count: teacherFeedback.word_count,
        points: teacherFeedback.points ?? question.calculatedPoints
      };
      setFeedback(feedback);
    }
  }, [teacherFeedback]);

  useEffect(() => {
    if (isManualUpdate) {
      updateFeedbackField(debouncedFeedback);
    }
  }, [debouncedFeedback]);

  const updateFeedbackField = (keyValue) => {
    if (keyValue.content) {
      keyValue.rich_text = {
        blocks: [
          {
            key: generateRandomKey(),
            text: keyValue.content,
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {}
          }
        ],
        entityMap: {}
      };
      keyValue.word_count = getWordCount(keyValue.content);
    }

    if (isEmpty(teacherFeedback)) {
      const id = uuid();
      dispatch(
        createInteraction({
          id: id,
          interactionId: question.id,
          interactionType: "feedbacks",
          interaction_type: INTERACTION_TYPES.FEEDBACK,
          interaction_subtype: INTERACTION_TYPES.QUESTION,
          user_type: USER_TYPE.TEACHER,
          ...keyValue
        })
      );
      httpCallables
        .interactionFunctions({
          id: id,
          interaction_id: question.id,
          task_id: task.id,
          submission_id: Number(submission_id),
          text_id: task.text_id,
          course_id: task.course_id,
          func_name: "createQuestionFeedback",
          ...keyValue
        })
        .catch((err) => {
          captureException(err);
        });
    } else {
      dispatch(
        updateInteraction({
          disableRollback: true, // we don't want to to erase users input if the request fails
          interaction: teacherFeedback,
          update: keyValue
        })
      );
    }
  };

  function splitHighlights(highlights, idPdf) {
    //this function split highlights to unique ranges
    let coll = [];
    highlights
      .filter((h) => h && h.cfi)
      .forEach((hl) => {
        let cfiParts = hl.cfi.split(","); // cfiBase:  cfiParts[0]
        let startCfi = cfiParts[0] + cfiParts[1] + ")"; // start: ‘epubcfi(/6/4[chap01ref]!/4[body01]/10[para05]/2/1:1)’
        let endCfi = cfiParts[0] + cfiParts[2];
        coll.push({ cfi: startCfi, val: 1 });
        coll.push({ cfi: endCfi, val: -1 });
      });
    let sortedHl = [...coll].sort(function (a, b) {
      let val = CFIByFileType.compare(a.cfi, b.cfi);
      if (val === 0) {
        if (a.val === b.val) return 0;
        return b.val < 0 ? 1 : -1;
      }
      return val;
    });
    let current = 0;
    let start = null;
    let hlSplitted = [];
    for (var i = 0; i < sortedHl.length; i++) {
      if (start == null) {
        if (sortedHl[i].val > 0) {
          start = sortedHl[i].cfi;
          current = 1;
        }
      } else {
        if (sortedHl[i].val > 0) {
          current++;
        } else {
          current--;
          if (current === 0) {
            let cfi = combinePartialCfisToCfi(start, sortedHl[i].cfi, {
              isPdf
            });
            hlSplitted.push({ cfi });
            start = null;
          }
        }
      }
    }
    return hlSplitted;
  }

  function selectSegColor(firstCfiSeg, secCfiSeg, startedSegs) {
    let selectedColor = null;

    //must be of different types i.e Teacher && Student
    const regEx = /\[.*\]/;
    firstCfiSeg.cfi = firstCfiSeg.cfi.replace(regEx, "");
    secCfiSeg.cfi = secCfiSeg.cfi.replace(regEx, "");

    if (
      firstCfiSeg.pos === secCfiSeg.pos &&
      firstCfiSeg.cfi !== secCfiSeg.cfi
    ) {
      selectedColor =
        firstCfiSeg.pos === POSITIONS.START
          ? colors[firstCfiSeg.type]
          : colors[secCfiSeg.type];
      //can be both same or different types
      // IF not same type mark as match
      // OR if same mark but a mark of different type has already started before and didn't end
      // FINALLY... if same type mark as the type of the first
    } else if (
      firstCfiSeg.pos === POSITIONS.START &&
      secCfiSeg.pos === POSITIONS.END
    ) {
      const otherType = MARKS_TYPES_ARRAY.find((a) => {
        return a !== firstCfiSeg.type;
      });
      selectedColor =
        startedSegs[otherType] || firstCfiSeg.type !== secCfiSeg.type
          ? colors.match
          : colors[firstCfiSeg.type];
    } else if (
      firstCfiSeg.pos === POSITIONS.END &&
      secCfiSeg.pos === POSITIONS.START &&
      firstCfiSeg.type === secCfiSeg.type
    ) {
      const otherType = MARKS_TYPES_ARRAY.find((a) => {
        return a !== firstCfiSeg.type;
      });
      selectedColor = startedSegs[otherType] ? colors[otherType] : null;
    }
    return selectedColor;
  }

  function createMatchedHighlights(teacherHl, studentHl, isPdf) {
    let highlights = [];
    let splitHl = [];

    if (!studentHl?.length) {
      if (!teacherHl?.length) {
        return [];
      }
      return (highlights = [...teacherHl]);
    } else if (!teacherHl?.length) return (highlights = [...studentHl]);

    const types = { 0: TYPES.TEACHER, 1: TYPES.STUDENT };
    const pos = { 0: POSITIONS.START, 1: POSITIONS.END };
    let splittedCfiToId = {};
    let allSplittedCfis = [];
    [teacherHl, studentHl].forEach((hl, idx) => {
      const type = types[idx];
      if (hl && hl.length) splitHl = splitHighlights(hl, isPdf);
      splitHl.forEach((el, index) => {
        let cfiParts = el.cfi.split(","); // cfiBase:  cfiParts[0]
        let startCfi = cfiParts[0] + cfiParts[1] + ")"; // start: ‘epubcfi(/6/4[chap01ref]!/4[body01]/10[para05]/2/1:1)’
        let endCfi = cfiParts[0] + cfiParts[2];
        const regEx = /(epubcfi\(\/\d+\/\d+)/;
        startCfi = startCfi.replace(regEx, "$1" + `[${type}]`);
        endCfi = endCfi.replace(regEx, "$1" + `[${type}]`);
        [startCfi, endCfi].forEach((cfiSeg, i) => {
          splittedCfiToId[cfiSeg] = {
            ...hl[index],
            type: type,
            pos: pos[i],
            cfi: cfiSeg
          };
          allSplittedCfis.push(cfiSeg);
        });
      });
    });

    let sortedSplittedCfis = allSplittedCfis.sort(function (a, b) {
      return CFIByFileType.compare(a, b);
    });

    let hasStarted = {};
    for (let i = 0; i < Object.keys(sortedSplittedCfis).length - 1; i++) {
      let firstSeg = splittedCfiToId[sortedSplittedCfis[i]];
      let secondSeg = splittedCfiToId[sortedSplittedCfis[i + 1]];
      hasStarted[firstSeg.type] = firstSeg.pos === POSITIONS.START;
      let segColor = selectSegColor(firstSeg, secondSeg, hasStarted);
      if (segColor) {
        let cfi = combinePartialCfisToCfi(firstSeg.cfi, secondSeg.cfi, {
          isPdf
        });
        if (isPdf)
          firstSeg.pdfPosition = [{ ...firstSeg.pdfPosition[0], cfi: cfi }];

        highlights.push({
          ...firstSeg,
          cfi,
          color: segColor
        });
      }
    }
    return highlights;
  }
  const getHighlights = useCallback(
    (teacherHl, studentHl, isPdf) => {
      //teacher  04E3FDד
      //student FFFF00
      //match 91fcc2
      try {
        const highlights = createMatchedHighlights(teacherHl, studentHl, isPdf);
        return highlights;
      } catch (e) {
        captureException(e, `failed creating matching highlights`);
        return [];
      }
    },
    [CFIByFileType, splitHighlights]
  );

  useEffect(() => {
    if (!isPdf) return;
    if (pdfHighlights) setPdfHighlights([]);

    let teacherHighlights = question.quotes;
    let highlights = getHighlights(teacherHighlights, studentHighlights, isPdf);

    highlights.forEach((highlight) => {
      const cfi = highlight.cfi;
      const color = highlight.color;
      const pdfPosition = highlight.pdfPosition;
    });
    setPdfHighlights(highlights);
  }, [isPdf, question.quotes, studentHighlights]);

  useEffect(() => {
    if (!rendition) return;

    let teacherHighlights = question.quotes;
    const isPdf = false;
    let highlights = getHighlights(teacherHighlights, studentHighlights, isPdf);
    removeAllAnotationsOfType(rendition, ANNOTATION_TYPES.HIGHLIGHT);
    highlights.forEach((highlight) => {
      const cfi = highlight.cfi;
      const color = highlight.color;
      rendition.annotations.add(
        ANNOTATION_TYPES.HIGHLIGHT,
        cfi,
        { id: highlight.id },
        () => {},
        ANNOTATION_TYPES.HIGHLIGHT.toLowerCase(),
        {
          "z-index": 20,
          "mix-blend-mode": "multiply",
          "fill-opacity": 0.8,
          fill: getHighlightColor(color, darkMode)
        }
      );
    });
  }, [darkMode, getHighlights, question.quotes, rendition, studentHighlights]);

  const addHighlightToAnswer = (cfi) => {
    setLinksCfi([...linksCfi, cfi]);
  };
  const removeHighlightFromAnswer = (hl) => {
    setLinksCfi(linksCfi.filter((el) => el !== hl.cfi));
  };

  const renderHighlightsComparison = useCallback(
    (params) => {
      if (params.shouldClose) {
        dispatch(closeAnnotatorBar());
      } else {
        addHighlightToAnswer(params.selection.cfi);
        let content = rendition && rendition.getContents()[0];

        if (content && content.window && content.window.getSelection()) {
          content.window.getSelection().empty();
        }
      }
    },
    [dispatch, rendition, addHighlightToAnswer]
  );
  const currentUser = firebaseApp.auth().currentUser;

  useEffect(() => {
    if (clickedMatch) {
      setOpenDialog(true);
      dispatch(setClickedMatch(false));
    }
  }, [clickedMatch, dispatch, setOpenDialog]);

  const rendeHighlightsDialog = () => {
    return (
      <Dialog
        open={openDialog}
        PaperProps={{
          style: {
            direction: textDirection,
            width: "60%",
            maxWidth: "60%"
          }
        }}
        aria-labelledby="form-dialog-title">
        <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
          {showMatch &&
            (question.type === "FIND_IN_TEXT" || question.includeCitation) && (
              <Box className={classes.colorsReader}>
                <Typography
                  style={{
                    // TODO: color should not be hard coded
                    background: getHighlightColor("#B9E7FF", darkMode)
                  }}
                  className={classes.yourColor}>
                  {currentUser.uid === task.creator
                    ? "Your "
                    : task.teacher_name + "'s "}
                  marks in blue
                </Typography>
                <Typography
                  style={{
                    // TODO: color should not be hard coded
                    background: getHighlightColor("#F5BC21", darkMode)
                  }}
                  className={classes.studentColor}>
                  {currentUser.uid === submission.owner
                    ? "Your "
                    : submission.user_name.split(" ")[0] + "'s "}
                  marks in yellow
                </Typography>

                <Typography
                  style={{
                    background: getHighlightColor("#BEF3BF", darkMode)
                  }}
                  className={classes.matchColor}>
                  Match in green
                </Typography>
              </Box>
            )}
          <DialogActions className={classes.dialogActions}>
            <Button
              variant="outlined"
              className={classes.dialogBtn}
              onClick={() => {
                setOpenDialog(false);
              }}>
              <FormattedMessage defaultMessage="Done" id="gr.confirm.btn" />
            </Button>
          </DialogActions>
        </DialogTitle>
        <DialogContent ref={epubElemRef} className={classes.dialog}>
          <div id="epubViewContainer" className={classes.epubViewContainer}>
            {isPdf ? (
              <PdfView
                zoom={zoom}
                highlightClicked={() => {}}
                isVisible={true}
                handleTextSelected={renderHighlightsComparison}
                highlights={pdfHighlights}
                // underlines={underlines}
                url={text.url}
              />
            ) : (
              <EpubView
                fullSize={true}
                fontSize={fontSize[fontSizeValue]}
                epubOptions={{ flow: "scrolled-doc" }}
                url={text.url}
                textDirection={text.text_language === "he" ? "rtl" : "ltr"}
                location={text.file_location}
                onRenditionCreated={() => {}}
              />
            )}
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  const handleSetFeedback = (feedback) => {
    setIsManualUpdate(true);
    setFeedback(feedback);
  };

  const renderContentTitle = () => {
    const handleBlur = (event) => {
      setIsTitleEditing(false);
      handleSetFeedback({ ...feedback, ...{ title: event.target.value } });
    };

    const handleKeyDown = (event) => {
      if (event.key === "Enter" || event.key === " ") {
        event.preventDefault();
        setIsTitleEditing(true);
      }
    };

    if (isTitleEditing) {
      return (
        <TextField
          value={feedback.title}
          onChange={(event) =>
            handleSetFeedback({ ...feedback, ...{ title: event.target.value } })
          }
          onBlur={handleBlur}
          placeholder={intl.formatMessage({
            id: "feedback.title",
            defaultMessage: "Title (optional)"
          })}
          variant="outlined"
          size="small"
          autoFocus
          fullWidth
          sx={{ marginBottom: "12px" }}
        />
      );
    }

    return (
      <Box
        onClick={() => !readOnly && setIsTitleEditing(true)}
        onKeyDown={handleKeyDown}
        sx={{
          cursor: readOnly ? "text" : "pointer",
          display: "flex",
          minHeight: "52px",
          alignItems: "center"
        }}>
        <Typography
          tabIndex={0}
          variant="body2"
          role="button"
          color={readOnly ? "text.primary" : "text.secondary"}
          sx={{
            display: "inline-block",
            marginBottom: "16px",
            fontSize: "20px",
            "&:hover": {
              backgroundColor: readOnly
                ? "rgba(0, 0, 0, 0)"
                : "rgba(0, 0, 0, 0.04)"
            }
          }}>
          {feedback.title ||
            intl.formatMessage({
              id: "feedback.title",
              defaultMessage: "Title (optional)"
            })}
        </Typography>
      </Box>
    );
  };

  // Render
  return (
    <Paper
      sx={{
        borderRadius: "12px",
        border: 1,
        borderColor: "rgba(0, 0, 0, 0.23)",
        boxShadow: 3,
        padding: "24px",
        width: "450px"
      }}>
      <Typography
        variant="body2"
        sx={{ color: "text.secondary", marginBottom: "8px", fontSize: "12px" }}>
        {intl.formatMessage({
          id: "feedback.questionFeedback",
          defaultMessage: "QUESTION FEEDBACK"
        })}
      </Typography>
      {renderContentTitle()}
      {readOnly ? (
        <Typography sx={{ marginBottom: "28px" }}>
          {feedback.content}
        </Typography>
      ) : (
        <TextField
          variant="outlined"
          multiline
          rows={3}
          sx={{ width: "100%", marginBottom: "24px" }}
          disabled={readOnly}
          label={intl.formatMessage({
            id: "feedback.typeYourFeedback",
            defaultMessage: "Type your feedback..."
          })}
          value={feedback.content}
          onChange={(e) =>
            handleSetFeedback({ ...feedback, ...{ content: e.target.value } })
          }
        />
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start"
        }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            sx={{ maxWidth: "80px", width: "100px", marginRight: "16px" }}
            variant="outlined"
            value={feedback.points >= 0 ? feedback.points : ""}
            onChange={(e) => {
              handleSetFeedback({
                ...feedback,
                ...{ points: Math.floor(e.target.value) }
              });
              if (e.target.value <= question.points || !question.points) {
                setValidPointsPerQuestion(true);
              } else setValidPointsPerQuestion(false);
            }}
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              readOnly: readOnly,
              style: { height: "36px" }
            }}
            label={intl.formatMessage({
              id: "feedback.points",
              defaultMessage: "Points"
            })}
            disabled={readOnly}
          />
          {!!question.points && (
            <Typography component="span" sx={{ color: "text.secondary" }}>
              {intl.formatMessage(
                {
                  id: "feedback.outOfPoints",
                  defaultMessage: "out of points"
                },
                { points: question.points }
              )}
            </Typography>
          )}
          {!validPointsPerQuestion && (
            <Typography
              component="span"
              sx={{ color: "#D32F2F", marginLeft: "8px" }}>
              {intl.formatMessage({
                id: "feedback.pointsExceeded",
                defaultMessage: "Maximum points exceeded"
              })}
            </Typography>
          )}
        </Box>
        {!readOnly && (
          <Tooltip
            title={intl.formatMessage({
              id: "feedback.showText",
              defaultMessage: "Show text"
            })}
            arrow
            placement="top">
            <IconButton
              onClick={() => setOpenDialog(true)}
              sx={{ marginLeft: "24px", justifyContent: "flex-end" }}
              aria-label="delete question"
              color="text.secondary">
              <LibraryBooksIcon size="small" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      {openDialog && rendeHighlightsDialog()}
    </Paper>
  );
}
