// Dependencies
import React, { useRef, useState, useEffect, useContext } from "react";
import { convertFromRaw, EditorState } from "draft-js";
import {
  firebaseApp,
  firebaseFunctions,
  httpCallables
} from "../../../firebase";
import { injectIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import ePub from "epubjs";
import { stemmer } from "stemmer";
import FlexSearch from "flexsearch";
import { captureException } from "../../../utils/errorHandlers";
import { useQuery } from "../../../hooks";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { setPangeaText } from "../../../redux/textsSlice";
import { setFeedbackTask, setSelectedText } from "../../../redux/taskSlice";
import { setProfile } from "../../../redux/userSlice";
import { setBreadcrumbs } from "../../../redux/readerActionsSlice";

// Components
import TaskFeedbackSidebar from "../TaskFeedback/TaskFeedbackSidebar";
import OpenAnswerLinked from "../TaskFeedback/OpenAnswerLinked";
import FeedbackQuestionContainer from "../TaskFeedback/FeedbackQuestionContainer";
import { getHighlightColor } from "../../../utils/colors";
import { ScrollToTopOnMount } from "../../../Router";

import makeStyles from "@mui/styles/makeStyles";
import ConceptChip from "../ConceptChip";

import {
  Box,
  Button,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
  Chip,
  TextField,
  Typography
} from "@mui/material";
import { useRendition } from "../../../RenditionContext";
import {
  selectDarkMode,
  selectTextDirection
} from "../../../redux/firestoreSelectors";

//Styles
const useStyles = makeStyles((theme) => {
  return {
    correct: {
      textTransform: "none",
      position: "absolute",
      right: "8px",
      color: "white",
      backgroundColor: "#0eac72",
      "& .MuiChip-label": {
        paddingLeft: "8px",
        paddingRight: "8px"
      }
    },
    correctInfo: {
      position: "absolute",
      right: "8px",
      color: "#0eac72",
      border: "1px solid #0eac72"
    },
    incorrect: {
      position: "absolute",
      right: "8px",
      backgroundColor: "#f9413e"
    },
    container: {
      paddingTop: "40px",
      display: "grid",
      gridTemplateColumns: "repeat(10,1fr)"
    },
    evalBox: {
      gridColumn: " 1 / 11 ",
      paddingLeft: "40px",
      paddingRight: "40px",
      paddingTop: "40px",
      paddingBottom: "40px"
    },
    findFeedback: {
      gridColumn: " 1 / 9 ",
      paddingLeft: "40px",
      paddingRight: "40px"
    },
    feedbackSide: {
      gridColumn: " 7 / 11 ",
      paddingRight: "32px"
    },
    paperContainer: {
      gridColumn: " 1 / 7 ",
      paddingLeft: "32px",
      paddingRight: "32px",
      textAlign: "left"
    },
    conceptContainer: {
      marginBotton: "8px",
      "& .MuiChip-root": {
        height: "24px",
        "&:first-child": {
          marginLeft: "0"
        }
      }
    },
    paper: {
      padding: "16px",
      gridColumn: " 2 / 11 ",
      height: "max-content",
      position: "relative",
      minHeight: "260px"
    },
    commentContainer: {
      fontSize: "20px",
      fontFamily: '"Crimson Pro" ,"Frank Ruhl Libre"'
    },
    readerViewContainer: {
      position: "relative",
      width: "100%",
      height: "max-content",
      minHeight: "calc(100vh - 120px)"
    },
    drawerContainer: {
      position: "relative",
      width: "100%",
      height: "max-content",
      minHeight: "calc(100vh - 120px)"
    },
    btnContainer: {
      position: "absolute",
      right: "16px"
    },
    actions: {
      padding: "16px",
      position: "relative",
      display: "block",
      minHeight: "64px"
    },
    pointsMsg: {
      display: "inline-block",
      lineHeight: "34px"
    },
    points: {
      display: "inline-block",
      border: "1px solid #168fee",
      borderRadius: "4px",
      paddingLeft: "8px",
      paddingRight: "8px",
      width: "64px",
      "& input": {
        textAlign: "center"
      }
    },
    adornment: {
      color: "#168fee"
    },
    eval: {
      color: "#168fee",
      fontSize: "20px",
      lineHeight: "32px",
      "& ::placeholder": {
        /* Chrome, Firefox, Opera, Safari 10.1+ */ color: "#168fee",
        opacity: 1
      },
      "& button": {
        visibility: "hidden"
      },
      "&:hover button": {
        visibility: "visible"
      }
    },
    writenEval: {
      "& :focus::placeholder": {
        color: "#bdc1c9"
      },
      "& ::placeholder": {
        color: "#9197a3"
      }
    },
    hidden: {
      visibility: "hidden",
      fontSize: "20px",
      position: "absolute",
      maxWidth: "95%"
    },
    dirLtr: {
      direction: "ltr"
    },
    content: {
      position: "relative",
      width: "100%",
      height: "max-content",
      minHeight: "calc(100vh - 120px)",
      display: "grid"
    },
    contentActionOpen: {
      gridTemplateColumns: "repeat(20,1fr)"
    },
    bookContainer: {
      gridColumn: " 7 / 21 ",
      position: "relative",
      height: "max-content",
      minHeight: "100%"
    },
    buttonCancel: {
      color: "#787877"
    },
    button: { textTransform: "none" }
  };
});
const TaskEvaluation = ({ intl, ...props }) => {
  // Hooks
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  let { submission_id, question_id } = useQuery();

  // Redux state
  const selectedCourse = useSelector(
    (state) => state.user.userProfile.selectedCourse
  );
  const textDirection = useSelector((state) => selectTextDirection(state));
  const darkMode = useSelector((state) => selectDarkMode(state));
  const task = useSelector((state) => state.task.feedbackTask);
  const userProfile = useSelector((state) => state.user.userProfile);

  // Ephemeral state
  const [feedback, setFeedback] = useState(null);
  const [evaluation, setEvaluation] = useState("");
  const [localQuestionIndex, setLocalQuestionIndex] = useState(
    question_id ? question_id : 0
  );
  const { rendition } = useRendition();
  const [teacherHighlightElements, setTeacherHighlightElements] = useState([]);
  const [studentHlElements, setStudentHlElements] = useState([]);
  const [readText, setReadText] = useState("");
  const [courses, setCourses] = useState(false);

  // Variables
  const currentUser = firebaseApp.auth().currentUser;
  const titlePlaceholder = intl.formatMessage({
    defaultMessage: "Enter evaluation title",
    id: "task.title.placeholder"
  });

  // Variables
  const concepts =
    feedback &&
    feedback.questions &&
    feedback.questions[localQuestionIndex] &&
    feedback.questions[localQuestionIndex].concepts;
  const question =
    task && task.questions && task.questions.questions[localQuestionIndex];
  const teacherAnswer =
    task && task.answers && task.answers.answers[localQuestionIndex];
  const answer =
    task &&
    task.student_answers &&
    task.student_answers.answers[localQuestionIndex];

  // Behavior
  const renderMultiAnswer = () => {
    return (
      <Box className={classes.paperContainer}>
        <Paper className={classes.paper}>
          <Box className={classes.commentContainer}>
            <Typography>Choose the correct answer</Typography>
            <RadioGroup
              name="answer"
              value={answer.choice}
              // className={clsx()}
            >
              {question.options.map((opt, index) => {
                return (
                  <Box className={classes.choiceContainer} key={index}>
                    <FormControlLabel
                      className={clsx(classes.label, classes.multiChoice)}
                      value={index}
                      key={index}
                      control={
                        <Radio
                          className={clsx(classes.radio)}
                          checked={index === parseInt(answer.choice)}
                        />
                      }
                      label={opt}
                    />
                    {index === parseInt(answer.choice) &&
                      parseInt(teacherAnswer.shouldSelect) === index && (
                        <Chip
                          className={classes.correct}
                          label={intl.formatMessage({
                            id: "task.multi.correct",
                            defaultMessage: "Correct"
                          })}
                        />
                      )}
                    {index !== parseInt(answer.choice) &&
                      parseInt(teacherAnswer.shouldSelect) === index && (
                        <Chip
                          className={classes.correctInfo}
                          label={intl.formatMessage({
                            id: "task.multi.correct",
                            defaultMessage: "Correct"
                          })}
                        />
                      )}

                    {index === parseInt(answer.choice) &&
                      parseInt(teacherAnswer.shouldSelect) !== index && (
                        <Chip
                          className={classes.incorrect}
                          label={intl.formatMessage({
                            id: "task.multi.incorrect",
                            defaultMessage: "Incorrect"
                          })}
                        />
                      )}
                  </Box>
                );
              })}
            </RadioGroup>
          </Box>
        </Paper>
      </Box>
    );
  };

  const renderOpenAnswer = () => {
    return (
      <Box className={classes.paperContainer}>
        <Box>
          <Paper className={classes.paper}>
            {concepts && concepts.length > 0 && (
              <Box className={classes.conceptContainer}>
                {concepts.map((concept, i) => {
                  return (
                    <ConceptChip
                      key={i}
                      found={concept.found}
                      concept={concept.concept}
                    />
                  );
                })}
              </Box>
            )}
            <Box className={classes.commentContainer}>
              <OpenAnswerLinked
                wordLimit={question?.wordLimit}
                showWordCount={true}
                key={localQuestionIndex}
                componentName={"answer" + localQuestionIndex}
                readOnly={true}
                selectionEvent={(selection) => {}}
                index={localQuestionIndex}
                comment={answer.comment}
                text={answer.text}
              />
            </Box>
          </Paper>
        </Box>
      </Box>
    );
  };

  // Behavior

  //set Breadcrumbs
  useEffect(() => {
    let parts = [];
    let course = false;
    let filteredCourses =
      task && courses && courses.filter((el) => el.id === task.course_id);
    if (filteredCourses && filteredCourses.length) {
      course = filteredCourses[0];
    }
    parts.push({
      course: course,
      url: "/profile",
      text: intl.formatMessage({
        id: "appBar.tasks",
        defaultMessage: "Assignments"
      })
    });

    task &&
      task.course_name &&
      parts.push({ course: course, url: "/profile", text: task.course_name });
    task &&
      task.name &&
      parts.push({
        course: course,
        url: `/adminTaskView?task_id=${task.id}`,
        text: task.name
      });

    task &&
      task.student &&
      task.student.uid !== currentUser.uid &&
      parts.push({
        course: course,
        url: `/adminTaskView?task_id=${task.id}`,
        text: task.student_name
      });

    dispatch(
      setBreadcrumbs({
        breadcrumbs: parts,
        blue: true
      })
    );
  }, [task, courses, currentUser.uid, dispatch, intl, submission_id]);

  //get courses
  useEffect(() => {
    var getMyCourses = firebaseFunctions.httpsCallable("courses-getMyCourses");

    getMyCourses().then((response) => {
      setCourses(response.data);
    });

    return function cleanup() {
      dispatch(setFeedbackTask(false));
    };
  }, [dispatch]);

  //get selected course
  useEffect(() => {
    if (
      task &&
      courses &&
      (!selectedCourse || !selectedCourse.id !== task.course_id)
    ) {
      let filteredCourses =
        task && courses && courses.filter((el) => el.id === task.course_id);
      if (filteredCourses && filteredCourses.length) {
        dispatch(
          setProfile({ ...userProfile, selectedCourse: filteredCourses[0] })
        );
      }
    }
  }, [task, courses, selectedCourse, dispatch]);

  useEffect(() => {
    const setExistingElements = (items, highlights) => {
      let toRemove = [];
      items &&
        Object.entries(items).forEach(([cfi, elem]) => {
          let highlightColl =
            highlights &&
            highlights.filter &&
            highlights.filter((hl) => elem.highlight.cfi === hl.cfi);
          if (!highlightColl || !highlightColl[0]) {
            toRemove.push(elem.highlight.cfi);
            rendition.annotations.remove(elem.highlight.cfi, "highlight");
          }
        });
      if (toRemove.length > 0) {
        const filtered = Object.keys(items)
          .filter((key) => !toRemove.includes(key))
          .reduce((obj, key) => {
            return {
              ...obj,
              [key]: items[key]
            };
          }, {});

        return [filtered, toRemove.length > 0];
      } else return [items, toRemove.length > 0];
    };

    const addMissingHighlights = (items, highlights, color) => {
      let add = false;

      highlights &&
        rendition &&
        rendition.manager &&
        highlights.forEach((highlight) => {
          let range = rendition.getRange(highlight.cfi);
          if (range && !(highlight.cfi in items)) {
            let hlStyleObj = {
              "z-index": 10,
              "mix-blend-mode": "multiply",
              "fill-opacity": 1,
              fill: getHighlightColor(color, darkMode)
            };
            let addedHighlight = rendition.annotations.highlight(
              highlight.cfi,
              {},
              (e) => {},
              "highlightClass",
              hlStyleObj
            );

            add = true;
            items[highlight.cfi] = { ...addedHighlight, highlight: highlight };
            //addedHighlight.mark.element.style.visibility = showHighlights ? "visible":"hidden";

            addedHighlight.mark.element.style.cursor = "pointer";
            addedHighlight.mark.element.style.pointerEvents = "visible";
          }
        });
      return [items, add];
    };

    const renderHighlights = () => {
      let items = teacherHighlightElements;
      let changed = false;
      let added = false;
      [items, changed] = setExistingElements(
        items,
        task.answers.answers[localQuestionIndex].quotes
      );
      //add new highlighyts
      [items, added] = addMissingHighlights(
        items,
        task.answers.answers[localQuestionIndex].quotes,
        "#168fee"
      );
      if (added || changed) {
        setTeacherHighlightElements(items);
      }
      items = studentHlElements;
      [items, changed] = setExistingElements(
        items,
        task.student_task_row.answers[localQuestionIndex].quotes
      );
      //add new highlighyts
      [items, added] = addMissingHighlights(
        items,
        task.student_task_row.answers[localQuestionIndex].quotes,
        "#ff80ff"
      );
      if (added || changed) {
        setStudentHlElements(items);
      }
    };

    if (task && rendition) {
      renderHighlights();
    }
  }, [
    task,
    teacherHighlightElements,
    studentHlElements,
    rendition,
    darkMode,
    localQuestionIndex
  ]);

  useEffect(() => {
    var EpubCFI = new ePub.CFI();

    let flexIndex = new FlexSearch({
      filter: [
        /*
      "a",
      "about",
      "above",
      "after",
      "again",
      "against",
      "all",
      "also",
      "am",
      "an",
      "and",
      "any",
      "are",
      "aren't",
      "as",
      "at",
      //"back",
      "be",
      "because",
      "been",
      "before",
      "being",
      "below",
      //"between",
      "both",
      "but",
      "by",
      "can",
      "cannot",
      "can't",
      "come",
      "could",
      "couldn't",
      //"day",
      "did",
      "didn't",
      "do",
      "does",
      "doesn't",
      "doing",
      "dont",
      "down",
      "during",
      "each",
      "even",
      "few",
      "first",
      "for",
      "from",
      "further",
      "get",
      //"give",
      "go",
      //"good",
      "had",
      "hadn't",
      "has",
      "hasn't",
      "have",
      "haven't",
      "having",
      "he",
      "hed",
      //"hell",
      "her",
      "here",
      "here's",
      "hers",
      "herself",
      "hes",
      "him",
      "himself",
      "his",
      "how",
      "how's",
      "i",
      "id",
      "if",
      "ill",
      "im",
      "in",
      "into",
      "is",
      "isn't",
      "it",
      "it's",
      "itself",
      "i've",
      "just",
      "know",
      "let's",
      "like",
      //"look",
      "make",
      "me",
      "more",
      "most",
      "mustn't",
      "my",
      "myself",
      "new",
      "no",
      "nor",
      "not",
      "now",
      "of",
      "off",
      "on",
      "once",
      //"one",
      "only",
      "or",
      "other",
      "ought",
      "our",
      "our's",
      "ourselves",
      "out",
      "over",
      "own",
      //"people",
      "same",
      "say",
      "see",
      "shan't",
      "she",
      "she'd",
      "shell",
      "shes",
      "should",
      "shouldn't",
      "so",
      "some",
      "such",
      //"take",
      "than",
      "that",
      "that's",
      "the",
      "their",
      "theirs",
      "them",
      "themselves",
      "then",
      "there",
      "there's",
      "these",
      "they",
      "they'd",
      "they'll",
      "they're",
      "they've",
      //"think",
      "this",
      "those",
      "through",
      "time",
      "to",
      "too",
      //"two",
      //"under",
      "until",
      "up",
      "us",
      //"use",
      "very",
      "want",
      "was",
      "wasn't",
      "way",
      "we",
      "wed",
      "well",
      "were",
      "weren't",
      "we've",
      "what",
      "what's",
      "when",
      "when's",
      "where",
      "where's",
      "which",
      "while",
      "who",
      "whom",
      "who's",
      "why",
      "why's",
      "will",
      "with",
      "won't",
      //"work",
      "would",
      "wouldn't",
      //"year",
      "you",
      "you'd",
      "you'll",
      "your",
      "you're",
      "your's",
      "yourself",
      "yourselves",
      "you've"*/
      ],

      /**
       * @type {Object<string, string>}
       * @export
       */

      stemmer: function (value) {
        // apply some replacements
        // ...

        return stemmer(value);
      },
      encode: textDirection === "rtl" ? false : "balance",
      rtl: textDirection === "rtl",
      split: textDirection === "rtl" ? /\s+/ : /\W+/
    });

    const subCompare = (needle, haystack, min_substring_length = 1) => {
      // Search possible substrings from largest to smallest:
      for (var i = needle.length; i >= min_substring_length; i--) {
        for (var j = 0; j <= needle.length - i; j++) {
          var substring = needle.substr(j, i);
          var k = haystack.indexOf(substring);
          if (k !== -1) {
            return {
              found: 1,
              substring: substring,
              needleIndex: j,
              haystackIndex: k
            };
          }
        }
      }
      return {
        found: 0
      };
    };

    const calcScore = (question, answer, teacherAnswer) => {
      let tempScore = 0;

      if (!answer.quotes || !answer.quotes.length) return 0;
      if (!teacherAnswer.quotes || !teacherAnswer.quotes.length) return 1;
      let teacherSortedQuotes = [...teacherAnswer.quotes].sort(function (a, b) {
        return EpubCFI.compare(a.cfi, b.cfi);
      });

      let studentSorted = [...answer.quotes].sort(function (a, b) {
        return EpubCFI.compare(a.cfi, b.cfi);
      });

      let studentInd = 0;
      let teacherInd = 0;

      while (
        studentInd < studentSorted.length &&
        teacherInd < teacherSortedQuotes.length
      ) {
        let cfiParts = studentSorted[studentInd].cfi.split(","); // cfiBase:  cfiParts[0]
        let studentStartCfi = cfiParts[0] + cfiParts[1] + ")"; // start: 'epubcfi(/6/4[chap01ref]!/4[body01]/10[para05]/2/1:1)'
        let studentEndCfi = cfiParts[0] + cfiParts[2];

        cfiParts = teacherSortedQuotes[teacherInd].cfi.split(","); // cfiBase:  cfiParts[0]
        let teacherStartCfi = cfiParts[0] + cfiParts[1] + ")"; // start: 'epubcfi(/6/4[chap01ref]!/4[body01]/10[para05]/2/1:1)'
        let teacherEndCfi = cfiParts[0] + cfiParts[2];
        if (EpubCFI.compare(studentEndCfi, teacherStartCfi) <= 0) {
          studentInd++;
        } else if (EpubCFI.compare(teacherEndCfi, studentStartCfi) <= 0) {
          teacherInd++;
        } else {
          let needle = studentSorted[studentInd].text;
          let haystack = teacherSortedQuotes[teacherInd].text;
          if (haystack.length < needle.length) {
            haystack = needle;
            needle = teacherSortedQuotes[teacherInd].text;
          }
          let retVal = subCompare(needle, haystack);
          if (retVal.found) {
            tempScore += retVal.substring.length;
          }

          if (EpubCFI.compare(studentEndCfi, teacherEndCfi) >= 0) {
            studentInd++;
          } else {
            teacherInd++;
          }
        }
      }
      let totalTeacher = teacherSortedQuotes.reduce(
        (acc, curr) => acc + curr.text.length,
        0
      );

      return (1.0 * tempScore) / totalTeacher;
    };

    const createQuestionFeedback = (task, question, index) => {
      let teacherAnswer = task.answers.answers[index];
      let studentAnswer = task.student_answers.answers[index];
      let text = studentAnswer && studentAnswer.tet;
      if (studentAnswer && studentAnswer.comment) {
        try {
          text = EditorState.createWithContent(
            convertFromRaw(studentAnswer.comment)
          )
            .getCurrentContent()
            .getPlainText("\u0001");
        } catch (error) {
          captureException(error);
        }
      }

      flexIndex.add(1, studentAnswer && text);

      /*

    {comment:'', points:-1, title:''
    */
      return {
        question: question,
        comment: "",
        points: -1,
        title: "",
        match: calcScore(question, studentAnswer, teacherAnswer),
        concepts:
          teacherAnswer.concepts && teacherAnswer.concepts.map
            ? teacherAnswer.concepts.map((concept, i) => {
                let results = flexIndex.search(stemmer(concept));
                let exactResults = flexIndex.search(concept);
                return {
                  concept: concept,
                  found: results.length > 0 || exactResults.length > 0
                };
              })
            : [],
        selectedChoice:
          teacherAnswer.shouldSelect >= 0 ? teacherAnswer.shouldSelect : -1,
        correctChoice: studentAnswer.choice >= 0 ? studentAnswer.choice : -1
      };
    };
    var getTaskById = firebaseFunctions.httpsCallable(
      "adminFunctions-getTaskSubmission"
    );
    let unsubscribe = null;
    getTaskById({ submission: submission_id }).then((response) => {
      let taskRow = response.data[0];
      setEvaluation(taskRow.evaluation);
      httpCallables
        .textFunctions({
          func_name: "getTextDownloadUrl",
          courseId: taskRow.course_id,
          fileUrl: taskRow.file_url
        })
        .then((response) => {
          if (response.data.success) {
            dispatch(
              setSelectedText({
                url: response.data.payload,
                file_location: taskRow.file_location
              })
            );
            setReadText({
              url: response.data.payload,
              file_location: taskRow.file_location
            });

            dispatch(setFeedbackTask(taskRow));

            if (taskRow.is_checked) {
              setLocalQuestionIndex(0);
            }
            dispatch(
              setPangeaText({
                ...taskRow,
                link: response.data.payload,
                defaultLocation: taskRow.file_location
              })
            );

            let feedback = {
              teacherSelectedQuestion: 0,
              studentSelectedQuestion: 0,
              grade: 0,
              totalPoints: taskRow.questions.questions.reduce(function (
                accumulator,
                current
              ) {
                return accumulator + parseInt(current.points);
              }, 0),
              questions: taskRow.questions.questions.map((question, index) =>
                createQuestionFeedback(taskRow, question, index)
              )
            };

            setFeedback({
              teacherSelectedQuestion: 0,
              studentSelectedQuestion: 0,
              ...feedback
            });
          } else {
            captureException(response.data.error);
          }
        });
    });
  }, [submission_id, dispatch, textDirection, task.feedbackcomment]);

  const updateFeedback = (feedback, questionIndex) => {
    if (questionIndex >= 0) {
      setLocalQuestionIndex(questionIndex);
    }
  };

  const submitEvaluation = () => {
    let func = firebaseFunctions.httpsCallable("adminFunctions-setEvaluation");
    func({ submission_id: submission_id, evaluation: evaluation })
      .then((resp) => {
        history.push("/adminTaskView/" + task.id);
      })
      .catch((err) => captureException(err));
  };
  //Render
  return (
    <div className={clsx(classes.readerViewContainer, classes.dirLtr)}>
      <ScrollToTopOnMount />

      {feedback && (
        <TaskFeedbackSidebar
          showMatch={true}
          editable={false}
          onQuestionIndex={(i) => {
            updateFeedback(feedback, i);
          }}
          isTeacher={false}
          selectedQuestionIndex={localQuestionIndex}
          task={task}
          feedback={feedback}
          questions={task && task.questions}
        />
      )}
      <div className={classes.drawerContainer} id="drawer-container">
        <div
          className={clsx(
            classes.content,
            darkMode && classes.darkMode,
            classes.contentActionOpen
          )}>
          <div
            id="readerViewBookContainer"
            className={clsx(classes.bookContainer)}>
            {task && (
              <Box className={classes.container}>
                <Box className={classes.evalBox}>
                  <TextField
                    variant="standard"
                    aria-label="task-evaluation-input"
                    defaultValue={evaluation}
                    onChange={(e) => {
                      setEvaluation(e.target.value);
                    }}
                    fullWidth={true}
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={submitEvaluation}>
                    Send evaluation
                  </Button>
                </Box>

                {question &&
                  ["OPEN_ENDED"].includes(question.interaction_subtype) &&
                  renderOpenAnswer()}
                {question &&
                  question.interaction_subtype === "FIND_IN_TEXT" &&
                  renderMultiAnswer()}
                <Box
                  className={clsx(
                    question &&
                      question.interaction_subtype &&
                      classes.feedbackSide,
                    question &&
                      question.interaction_subtype === "FIND_IN_TEXT" &&
                      classes.findFeedback
                  )}>
                  {feedback && (
                    <FeedbackQuestionContainer
                      showMatch={true}
                      key={localQuestionIndex}
                      readOnly={true}
                      index={localQuestionIndex}
                      feedback={feedback}
                      readText={readText}
                      task={task}
                      updateFeedback={updateFeedback}
                    />
                  )}
                  <Box className={clsx(classes.action, classes.right)}></Box>
                </Box>
              </Box>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(TaskEvaluation);
