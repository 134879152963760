import React, { useEffect } from "react";
import { firebaseFunctions, httpCallables } from "../../../../firebase";
//Components
import SelectCourseTasks from "./SelectCourseTasks";
import SelectTaskQuestions from "./SelectTaskQuestion";
import SelectCourse from "./SelectCourse";
import SelectStudent from "./SelectStudent";

// Styles
import { Box } from "@mui/material";

export const QuestionBotSettings = ({
  setAllCourses,
  setTaskSubmissions,
  setTaskQuestions,
  allCourses,
  setSelectedCourse,
  selectedCourse,
  setCourseUsers,
  setCourseTasks,
  taskSubmissions,
  setSelectedSubmission,
  courseTasks,
  setSelectedTask,
  selectedTask,
  setSelectedUser,
  selectedUser,
  courseUsers,
  taskQuestions,
  setSelectedQuestion,
  selectedQuestion
}) => {
  // Firebase functions calls
  const getAllCourses = firebaseFunctions.httpsCallable(
    "adminFunctions-getAllCourses"
  );

  // Get all Courses
  useEffect(() => {
    getAllCourses().then(({ data }) => {
      const sortedCourses = data.sort((a, b) => a.id - b.id);

      if (sortedCourses.length > 0 && sortedCourses[0] !== selectedCourse) {
        setAllCourses(sortedCourses);
        setSelectedCourse(sortedCourses[0]);
      }
    });
  }, []);

  // Get task Questions only when selectedTask is changed
  useEffect(() => {
    if (!selectedTask?.id) return;
    httpCallables
      .taskFunctions({
        func_name: "readTask",
        task_id: Number(selectedTask.id)
      })
      .then(({ data }) => {
        if (data) {
          const questions = JSON.parse(data);
          const { interactions, submissions } = questions;

          setTaskSubmissions(submissions);
          setTaskQuestions(interactions);
          setSelectedQuestion(interactions[0]);
        }
      });
  }, [selectedTask]);

  // Get course students only when selectedCourse is changed

  useEffect(() => {
    const getAllUsers = firebaseFunctions.httpsCallable(
      "adminFunctions-getAllCourseUsers"
    );
    const fetchStudentsCallback = async () => {
      const response = await getAllUsers({ course: selectedCourse.id });
      const students = response.data.filter((user) => user.role === "Student");

      setCourseUsers(students);
      setSelectedUser(response.data[0]);
    };

    fetchStudentsCallback();
  }, [selectedCourse]);

  // Get course tasks only when selectedCourse is changed
  useEffect(() => {
    const getAllCourseTasks = firebaseFunctions.httpsCallable(
      "adminFunctions-getAllCourseTasks"
    );

    getAllCourseTasks({ course: selectedCourse.id }).then((response) => {
      const tasks = [...response.data];
      setCourseTasks(tasks);
      setSelectedTask(tasks[0]);
    });
  }, [selectedCourse]);

  // set the selected submission when user changes
  useEffect(() => {
    const submission = taskSubmissions.filter(
      (s) => s.owner === selectedUser?.course_user
    );
    setSelectedSubmission(submission[0]);
  }, [selectedUser]);

  return (
    <Box sx={{ display: "flex", flexFlow: "row nowrap" }}>
      <Box>
        <SelectCourse
          allCourses={allCourses}
          setSelectedCourse={setSelectedCourse}
          selectedCourse={selectedCourse}
        />
      </Box>
      <Box>
        <SelectCourseTasks
          courseTasks={courseTasks}
          setSelectedTask={setSelectedTask}
          selectedTask={selectedTask}
        />
      </Box>
      <Box>
        <SelectStudent
          selectedCourse={selectedCourse}
          setSelectedUser={setSelectedUser}
          selectedUser={selectedUser}
          courseUsers={courseUsers}
        />
      </Box>
      <Box>
        <SelectTaskQuestions
          taskQuestions={taskQuestions}
          setSelectedQuestion={setSelectedQuestion}
          selectedQuestion={selectedQuestion}
        />
      </Box>
    </Box>
  );
};
