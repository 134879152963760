// Dependencies
import React, { useState } from "react";
import { firebaseFunctions } from "../../firebase";

//Components
import { captureException } from "../../utils/errorHandlers";
import { enqueueFlashMessage } from "../../redux/userSlice";
import { selectAlertsDuration } from "../../redux/firestoreSelectors";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

//Styles
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField
} from "@mui/material";

function CourseRollOver({ setStatus, course_id }) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [duplicatedCourseId, setDuplicatedCourseId] = useState("");
  const [includeTasks, setIncludeTasks] = useState(false);
  const alertsDuration = useSelector((state) => selectAlertsDuration(state));

  function duplicateCourseData(
    course_id,
    duplicatedCourseId,
    includeTasks = false
  ) {
    const duplicateData = firebaseFunctions.httpsCallable(
      "adminFunctions-duplicateCourseTexts"
    );
    try {
      duplicateData({
        new_course_id: course_id,
        original_course_id: duplicatedCourseId,
        includeTasks: includeTasks
      }).then((response) => {
        const data = response.data;
        if (data) setStatus("loading");
      });
    } catch (err) {
      captureException(
        err,
        `Failed to duplicate texts from course ${duplicatedCourseId}`
      );
      dispatch(
        enqueueFlashMessage({
          message: intl.formatMessage({
            id: "duplicateCourseText.fail",
            defaultMessage: `Failed to duplicate texts from course ${duplicatedCourseId}`
          }),
          severity: "error",
          duration: alertsDuration
        })
      );
      return false;
    }
  }
  return (
    <>
      <TextField
        variant="outlined"
        onChange={(e) => {
          setDuplicatedCourseId(e.target.value);
        }}
        value={duplicatedCourseId}
        aria-label="course-academic-year-input"
        autoFocus
        margin="dense"
        id="course id to extract data from"
        label="course id for extract"
        type="number"
        sx={{ width: "250px" }}
        fullWidth
      />
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={includeTasks}
              onChange={() => {
                setIncludeTasks(!includeTasks);
              }}
            />
          }
          label={intl.formatMessage({
            id: "duplicateCourse.includeTasks",
            defaultMessage: "Include tasks"
          })}
        />
      </FormGroup>
      <Button
        onClick={() =>
          duplicateCourseData(course_id, duplicatedCourseId, includeTasks)
        }
        color="primary"
        size="sm"
        sx={{ height: "50px" }}>
        Duplicate Course
      </Button>
    </>
  );
}

export default CourseRollOver;
