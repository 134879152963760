import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { firebaseFunctions, httpCallables } from "../../firebase";
import makeStyles from "@mui/styles/makeStyles";
import {
  Button,
  Dialog,
  DialogTitle,
  TextField,
  DialogActions,
  DialogContent,
  Grid
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useIntl } from "react-intl";
import Autocomplete from "@mui/material/Autocomplete";

const useStyles = makeStyles((theme) => ({
  startDate: {
    "& .MuiPickersDay-current": {
      color: "#168fee"
    },
    "& .MuiPickersDay-daySelected": {
      color: "#fff"
    }
  },
  acceptUntil: {
    "& .MuiPickersDay-current": {
      color: "#168fee"
    },
    "& .MuiPickersDay-daySelected": {
      color: "#fff"
    },
    width: "100%"
  }
}));

function CreateCourseDialog({
  newCourseDialogVisible,
  setNewCourseDialogVisible,
  setCourses,
  courses
}) {
  // Hooks
  const classes = useStyles();
  const intl = useIntl();

  // Ephemeral State
  const [courseName, setCourseName] = useState("");
  const [year, setYear] = useState(null);
  const [semester, setSemester] = useState("");
  const [courseStart, setCourseStart] = useState(null);
  const [courseEnd, setCourseEnd] = useState(null);
  const [institutionId, setInstitutionId] = useState(null);
  const [timeZone, setTimeZone] = useState(null);
  const [institutionsList, setInstitutionsList] = useState([]);

  // Variables
  const addCourse = firebaseFunctions.httpsCallable(
    "adminFunctions-createCourse"
  );

  const timeZonesList = Intl.supportedValuesOf("timeZone");

  // Behavior
  const saveCourse = () => {
    addCourse({
      name: courseName,
      year: year,
      semester: semester,
      course_start: courseStart,
      course_end: courseEnd,
      institution_id: institutionId,
      timezone: timeZone
    }).then((response) => {
      if (response.data.length > 0)
        setCourses([
          ...courses,
          {
            id: response.data[0].id,
            name: courseName,
            academic_year: year,
            semester: semester,
            course_start: courseStart,
            course_end: courseEnd,
            institution_id: institutionId,
            timezone: timeZone
          }
        ]);
    });
    handleClose();
  };

  function handleClose() {
    setYear(null);
    setSemester(null);
    setCourseName(null);
    setCourseStart(null);
    setCourseEnd(null);
    setInstitutionId(null);
    setTimeZone(null);
    setNewCourseDialogVisible(false); // Fixed typo in function call
  }

  function generateAcademicYears() {
    const yearsList = [];
    const currentYear = new Date().getFullYear();
    for (let i = 0; i < 8; i++) {
      const startYear = currentYear - i;
      const endYear = startYear + 1;
      yearsList.push(`${startYear} - ${endYear}`);
    }
    return yearsList;
  }

  useEffect(() => {
    httpCallables
      .institutionsFunctions({ func_name: "getAllInstitutions" })
      .then(({ data }) => {
        setInstitutionsList(data);
      });
  }, []);

  // Render
  return (
    <Dialog
      open={newCourseDialogVisible} // Fixed typo in prop name
      onClose={handleClose}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Add a course</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              onChange={(e) => {
                setCourseName(e.target.value);
              }}
              autoFocus
              margin="dense"
              id="course_name"
              label="Name"
              type="text"
              value={courseName}
              fullWidth
              inputProps={{
                "aria-label": "course-name-input" // Added aria-label
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <Autocomplete
              id="academic_year"
              options={generateAcademicYears()}
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  {...params}
                  name="academic_year"
                  aria-label="course-academic-year-input"
                  label={"Academic year"}
                  margin={"dense"}
                  value={year}
                  type="text"
                />
              )}
              onChange={(event, newValue) => {
                setYear(newValue);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              onChange={(e) => {
                setSemester(e.target.value);
              }}
              value={semester}
              margin="dense"
              id="semester"
              label="Semester"
              type="text"
              fullWidth
              inputProps={{
                "aria-label": "course-semester-input" // Added aria-label
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <DatePicker
              id="startDate"
              name="startDate"
              label={"Start date"}
              openTo="day"
              className={classes.acceptUntil}
              DialogProps={{ className: classes.startDate }}
              minDate={new Date()}
              value={courseStart}
              emptyLabel={intl.formatMessage({
                id: "tasks.pickDate",
                defaultMessage: "Choose Course Start Date"
              })}
              onChange={(e) => {
                setCourseStart(e);
              }}
              leftArrowButtonProps={{ "aria-label": "previous month" }}
              rightArrowButtonProps={{ "aria-label": "next month" }}
              renderInput={(params) => (
                <TextField variant="outlined" {...params} />
              )}
              inputProps={{
                "aria-label": "course-start-date-input" // Added aria-label
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              id="endDate"
              className={classes.acceptUntil}
              DialogProps={{ className: classes.startDate }}
              label={"End date"}
              openTo="day"
              value={courseEnd}
              emptyLabel={intl.formatMessage({
                id: "tasks.pickDate",
                defaultMessage: "Choose Date"
              })}
              onChange={(e) => {
                setCourseEnd(e);
              }}
              leftArrowButtonProps={{ "aria-label": "previous month" }}
              rightArrowButtonProps={{ "aria-label": "next month" }}
              renderInput={(params) => (
                <TextField variant="outlined" {...params} />
              )}
              inputProps={{
                "aria-label": "course-end-date-input" // Added aria-label
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id="institution"
              options={institutionsList}
              getOptionLabel={(option) => {
                return option.name;
              }}
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  {...params}
                  name="institution"
                  aria-label="institution-input"
                  label={"Institution"}
                />
              )}
              onChange={(event, newValue) => {
                setInstitutionId(newValue ? newValue.id : null);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id="TimeZone"
              options={timeZonesList}
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  {...params}
                  name="TimeZone"
                  aria-label="time-zone-input"
                  label={"Course Time Zone"}
                />
              )}
              onChange={(event, newValue) => {
                setTimeZone(newValue);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={saveCourse} color="primary">
          Create Course
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CreateCourseDialog.propTypes = {
  courses: PropTypes.array,
  setCourses: PropTypes.func,
  newCourseDialogVisible: PropTypes.bool,
  setNewCourseDialogVisible: PropTypes.func
};

export default CreateCourseDialog;
