import { v4 as uuid } from "uuid";

export const HIGHLIGHTS = {
  CONTEXT: {
    ANSWERS: "Answers",
    HIGHLIGHTS: "Highlights"
  },
  COLOR: {
    STANDARD_TASK: "#fccbfc",
    GREEN: "#BEF3BF",
    CYAN: "#B9E7FF",
    ORANGE: "#ffa284",
    RED: "#FF7F74",
    YELLOW: "#FFE690",
    PINK: "#ECC5FF"
  }
};

export const CHAT = {
  TYPE: {
    REVIEW: "REVIEW",
    READER: "READER"
  }
};

export const FAB_STYLE_VIEW = {
  EXPANDED: "expanded",
  MINIMIZED: "minimized"
};

export const USER_ACTIONS = {
  TASK_DELETE_AI_QUESTION: "TASK_DELETE_AI_QUESTION",
  TASK_ADD_MANUAL_QUESTION: "TASK_ADD_MANUAL_QUESTION",
  TASK_EDIT_AI_QUESTION: "TASK_EDIT_AI_QUESTION",
  TASK_GENERATE_AI_QUESTION: "TASK_GENERATE_AI_QUESTION",
  TASK_REGENERATE_AI_QUESTION: "TASK_REGENERATE_AI_QUESTION",
  TASK_GENERATE_AI_ASSIGNMENT: "TASK_GENERATE_AI_ASSIGNMENT",
  TASK_PUBLISH: "TASK_PUBLISH",
  TASK_CREATION_SESSION: "TASK_CREATION_SESSION"
};

export const TASK = {
  TYPE: {
    STANDARD: "standard",
    GUIDED_READING: "guidedReading",
    PEER_REVIEW: "peerReview"
  },
  SUBMISSION_STATUS: {
    SUBMITTED: "Submitted",
    PENDING: "Pending",
    PENDING_REVIEW: "pendingReview",
    ACTIVE: "Active",
    GRADED: "Graded",
    LATE: "late",
    MISSED: "missed"
  },
  STEPS: {
    REVIEW: "review"
  },
  QUESTION_TYPES: {
    FIND_IN_TEXT: "FIND_IN_TEXT",
    OPEN_ENDED: "OPEN_ENDED",
    MULTI_CHOICE: "MULTI_CHOICE",
    PEER_REVIEW: "PEER_REVIEW",
    GUIDED_READING: "GUIDED_READING"
  }
};

export const QUESTION_INTL = {
  FIND_IN_TEXT: {
    translationId: "task.type.find",
    defaultMessage: "Find in Text"
  },
  OPEN_ENDED: {
    translationId: "task.type.open",
    defaultMessage: "Open Ended"
  },
  MULTI_CHOICE: {
    translationId: "task.type.multiple",
    defaultMessage: "Multiple Choice"
  },
  GUIDED_READING: {
    translationId: "task.type.guidedReading",
    defaultMessage: "Guided Reading"
  },
  PEER_REVIEW: {
    translationId: "task.type.peerReview",
    defaultMessage: "Peer Review"
  }
};

export const QUESTION = {
  FIND_IN_TEXT: {
    //TODO: this is redundent, need to delete and change to const.interaction_subtypes
    interaction_subtype: "FIND_IN_TEXT",
    content: "",
    includeCitation: true,
    points: 0,
    wordLimit: -1,
    quotes: [],
    options: [],
    aiQuestion: false
  },
  OPEN_ENDED: {
    //TODO: this is redundent, need to delete and change to const.interaction_subtypes
    interaction_subtype: "OPEN_ENDED",
    content: "",
    includeCitation: true,
    points: 0,
    wordLimit: -1,
    concepts: [],
    quotes: [],
    options: [],
    question_context: TASK.TYPE.STANDARD,
    aiQuestion: false
  },
  MULTI_CHOICE: {
    //TODO: this is redundent, need to delete and change to const.interaction_subtypes
    interaction_subtype: "MULTI_CHOICE",
    content: "",
    includeCitation: true,
    points: 0,
    wordLimit: -1,
    shouldSelect: -1,
    quotes: [],
    options: [],
    aiQuestion: false
  },
  PEER_REVIEW: {
    //TODO: this is redundent, need to delete and change to const.interaction_subtypes
    interaction_subtype: "PEER_REVIEW",
    content: "",
    includeCitation: false,
    points: 0,
    wordLimit: -1,
    options: [],
    aiQuestion: false
  }
};

export function Question(QUESTION, order, context = TASK.TYPE.STANDARD) {
  return {
    ...QUESTION,
    ...{ tempId: uuid(), order: order, question_context: context }
  };
}

export const USER_TYPE = {
  TEACHER: "TEACHER",
  STUDENT: "STUDENT",
  MENTOR: "MENTOR",
  ADMIN: "ADMIN"
};

export const appActions = {
  SHOULD_LOG: "SHOULD_LOG",
  LOGIN: "LOGIN",
  READ_FEEDBACK: "READ_FEEDBACK",
  READ_TASK: "READ_TASK",
  CREATE_TASK: "CREATE_TASK",
  READ_TEXT: "READ_TEXT",
  UPDATE_INTERACTION: "UPDATE_INTERACTION",
  CREATE_INTERACTION: "CREATE_INTERACTION",
  DELETE_INTERACTION: "DELETE_INTERACTION",
  SHOW_IN_TEXT: "SHOW_IN_TEXT",
  ARTICLE_READING_EVENT: "ARTICLE_READING_EVENT"
};

export const INTERACTION_TYPES = {
  COMMENT: "COMMENT",
  QUOTE: "QUOTE",
  QUESTION: "QUESTION",
  ANSWER: "ANSWER",
  FEEDBACK: "FEEDBACK",
  REVIEW: "REVIEW",
  READER: "READER",
  CONTAINER: "CONTAINER",
  SUGGESTION: "SUGGESTION",
  REASON: "REASON"
};

export const INTERACTION_SUBTYPES = {
  MULTI_CHOICE: "MULTI_CHOICE",
  FIND_IN_TEXT: "FIND_IN_TEXT",
  PEER_REVIEW: "PEER_REVIEW",
  GUIDED_READING: "GUIDED_READING",
  OPEN_ENDED: "OPEN_ENDED",
  COMMENT: "COMMENT",
  QUOTE: "QUOTE",
  DEFAULT: "DEFAULT",
  TASK: "TASK",
  REPLY: "REPLY",
  SELECTION: "SELECTION"
};

export const PRIVACY = {
  ME_ONLY: "ME_ONLY",
  PUBLIC: "PUBLIC",
  ME_AND_TEACHER: "ME_AND_TEACHER",
  PEERS_IN_COURSE: "PEERS_IN_COURSE",
  SELECTED_FEW: "SELECTED_FEW",
  FEEDBACK: "FEEDBACK"
};

export const ANNOTATION_TYPES = {
  HIGHLIGHT: "HIGHLIGHT",
  UNDERLINE: "UNDERLINE",
  THREAD: "THREAD"
};

export const BREAKPOINTS = {
  DESKTOP: 1440
};

export const PRIMARY_SIDEBAR_STATE = {
  EXPANDED: "EXPANDED",
  COLLAPSED: "COLLAPSED"
};

export const SECONDARY_SIDEBAR_STATE = {
  COLLAPSED: "COLLAPSED",
  THUMBNAILS: "THUMBNAILS",
  SUGGESTIONS: "SUGGESTIONS",
  COMMENTS: "COMMENTS"
};

export const COMMENT_PANEL_VIEW = {
  SINGLE_THREAD: "SINGLE_THREAD",
  NEW_COMMENT: "NEW_COMMENT",
  SELECTED_THREADS: "SELECTED_THREADS",
  ALL_THREADS: "ALL_THREADS"
};

export const FEATURES = {
  READER: "READER",
  TASK: "TASK",
  SUGGESTIONS: "SUGGESTIONS",
  DEFAULT: "DEFAULT",
  CROSS_FEATURE: "CROSS_FEATURE",
  COMMENTS: "COMMENTS",
  ANALYTICS: "ANALYTICS",
  INTERACTIONS: "INTERACTIONS"
};

export const READER_MODES = {
  GUIDED_READING: "GUIDED_READING",
  FREE_ANNOTATIONS: "FREE_ANNOTATIONS"
};

export const USER_PROFILE = {
  CUSTOM_CONFIG_PATH: "userProfiles/customConfig",
  CROSS_FEATURE_CONFIG_PATH: "userProfiles/crossFeatureConfig"
};

export const LOCALES = {
  en: { tag: "en", language: "English", textDirection: "ltr", rtl: false },
  he: { tag: "he", language: "Hebrew", textDirection: "rtl", rtl: true }
};

export const NOTIFICATION_INTL = {
  STUDENT: {
    notifyStudentsOnNewTask: `A new task, $DATA, is waiting for you`,
    notifyStudentOnFeedback: "A new feedback is wating for you",
    notifyOnPeerReviewReflection: `$DATA is waiting for your review`,
    notifyOnPeerReviewResponse: `$DATA reviewed your work`,
    notifyCourseOnNewComment: "New comment on text $DATA",
    notifyOnPeerReviewReply: `$DATA replied to your review`,
    notifyStudentOnNewCommentReply: "$DATA replied to your comment on $DATA2"
  },
  TEACHER: {
    notifyTeachersOnSubmission: `$DATA submitted a task`,
    notifyOnPeerReviewReflection: `$DATA submitted a reflection`,
    notifyCourseOnNewComment: "New comment on text $DATA",
    notifyOnPeerReviewResponse: `$DATA submitted a review`,
    notifyOnPeerReviewReply: "$DATA replied to your review"
  },
  COURSE: {
    notifyCourseOnNewComment: "New comment on text $DATA",
    notifyCourseOnNewCommentReply: "$DATA replied to a comment on $DATA2"
  }
};

export const IDLE_PERIOD = 1000 * 60 * 10; // 10 min
export const ACTIVITY_EVENT = { START: "START", END: "END" };
export const TEXT_TYPE = {
  PDF: "PDF",
  EPUB: "EPUB"
};

export const ENGAGEMENT_SCORE_COEFFICIENT = {
  LOW_SCORE: 100,
  MEDIUM_SCORE: 50,
  HIGH_SCORE: 0,
  MISSED: 100,
  LATE: 50,
  LOW: "LOW",
  MEDIUM: "MEDIUM",
  HIGH: "HIGH"
};

export const ACTION_OBJ = {
  READING_SESSIONS: "ReadingTime",
  WRITING_SESSIONS: "writingTime",
  GUIDED_READING_INTERACTIONS: "questionsCreated",
  REAL_TIME_INTERACTIONS: "comments",
  SESSIONS: "sessions"
};

export const CHART_ACTIVITY_BUCKET = {
  PER_CLASS: "daily_activity_per_class",
  PER_USER: "daily_activity_per_active_users"
};

export const PEER_REVIEW_TASK = {
  REFLECTION: {
    MY_REFLECTION: "My reflection"
  },
  REVIEW: {
    MY_REVIEW: "My review",
    REVIEW: "review",
    USER_REVIEW: `$DATA's review`
  },
  FEEDBACK: {
    MY_FEEDBACK: "My feedback",
    TEACHER_FEEDBACK: "Teacher feedback"
  },
  SIZE: {
    LARGE: "large",
    SMALL: "small"
  },
  ZEN_MODE: {
    FULL_SCREEN: "Full screen",
    EXIT_FULL_SCREEN: "Exit full screen",
    ICON_FULL: "icon.EnterFullScreen",
    ICON_EXIT: "icon.exitFullScreen"
  }
};

export const COURSE_ACTIONS_FOR_ANALYSES = {
  SESSIONS: "General engagement",
  READING_SESSIONS: "Reading time",
  WRITING_SESSIONS: "Writing time",
  REAL_TIME_INTERACTIONS: "Comments",
  QUOTE_INTERACTIONS: "Citations",
  GUIDED_READING_INTERACTIONS: "Questions"
};

export const SAVED_THUMBNAILS_CONFIG = {
  FORMAT: "webp",
  QUALITY: 0.6
};

export const REPORTS_VERSION = {
  NEW: "NEW",
  OLD: "OLD"
};

export const SUBMISSION_STATUS = {
  MISSED: "missed",
  LATE_PENDING: "latePending",
  LATE: "late",
  ON_TIME: "onTime",
  PENDING: "pending"
};

export const ENGAGEMENT_CATEGORIES = {
  MODERATE: "Moderate",
  HIGH: "High",
  EXTRAORDINARY: "Extraordinary"
};

export const FEATURE_FLAGS = {
  E_MENTOR_REVIEW: "E_MENTOR_REVIEW",
  E_MENTOR_READER: "E_MENTOR_READER",
  E_MENTOR_JOURNEY: "E_MENTOR_JOURNEY",
  NEW_CREATE_TASK: "NEW_CREATE_TASK",
  NEW_CREATE_TASK_AI: "NEW_CREATE_TASK_AI",
  HELP_CENTER: "HELP_CENTER",
  TEACHER_ANALYTICS: "TEACHER_ANALYTICS"
};

export const SECONDARY_SIDEBAR_ACTION_BUTTONS = {
  COMMENTS: "Comments",
  THUBMNAILS: "Thumbnails",
  SUGGESTIONS: "Suggestions",
  COLLAPSED: "Collapsed"
};
