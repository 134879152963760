import React, {
  useState,
  useEffect,
  useReducer,
  useCallback,
  useMemo
} from "react";
import { httpCallables } from "../../../../firebase";
import { startOfDay } from "date-fns";
import { useQuery } from "../../../../hooks";
import { v4 as uuid } from "uuid";
import { captureException } from "../../../../utils/errorHandlers";
import { useDispatch } from "react-redux";
import { addSnackbarItem } from "../../../../redux/snackbarSlice";
import ScrollBox from "../../../SharedComponents/ScrollBox";
import EngagementChart from "./charts/classEngagementChart/EngagmentChart";
import UsersTable from "./UsersTable";
import makeStyles from "@mui/styles/makeStyles";
import DateRangeSelection from "../../../SharedComponents/DateRangeSelection";
import WidgetHeader from "../../../SharedComponents/WidgetHeader";
import { PieChart } from "./charts/PieChart";
import { CHART_NAMES, CHARTS_VIEW_TYPE } from "../consts";
import {
  getWeekChartsDataFromApiResponse,
  getTaskChartsDataFromApiResponse
} from "./transformUtils";
import TimeOnAssignmentReport from "../../../SharedComponents/reports/assignments/TimeOnAssignmentReport.js";
import StackedPieChartBody from "./charts/StackedPieChartBody";
import ActivityReportGraphs from "./graphs/ActivityReportGraphs";
import { PangeaSpinner } from "../../../SharedComponents";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Typography,
  Grid
} from "@mui/material";
import { isEmpty } from "lodash";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    marginInline: theme.spacing(10),
    marginInlineEnd: theme.spacing(10),
    borderRadius: "15px",
    marginBlock: theme.spacing(3),
    backgroundColor: theme.palette.background.paper
  },
  dateRangeSelection: {
    display: "flex",
    paddingBlockStart: theme.spacing(6),
    paddingBlockEnd: theme.spacing(4),
    marginInline: theme.spacing(10),
    marginInlineEnd: theme.spacing(5),
    width: "500px"
  },
  chartsSection: {
    paddingInline: theme.spacing(10)
  },
  engagementChart: {
    height: 700
  }
}));

function usersReducer(state, action) {
  switch (action.type) {
    case "set":
      return { ...action.payload };
    case "toggle": {
      let { user } = action.payload;
      return {
        ...state,
        [user]: { ...state[user], selected: !state[user].selected }
      };
    }
    case "update": {
      let { user: userToUpdate, ...rest } = action.payload;
      return {
        ...state,
        [userToUpdate]: { ...state[userToUpdate], ...rest }
      };
    }
    default:
      throw new Error();
  }
}

export default function CourseActivityReport() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { course_id } = useQuery();
  const [start, setStart] = useState(() => startOfDay(new Date()));
  const [end, setEnd] = useState(() => startOfDay(new Date()));
  const [course, setCourse] = useState(null);
  const [chartsData, setChartsData] = useState(null);
  const [totalSubmissions, setTotalSubmissions] = useState(null);
  const [view, setView] = useState("task");
  const [users, dispatchUsers] = useReducer(usersReducer, {});
  const [weekChartData, setWeekChartsData] = useState(null);
  const [taskChartData, setTaskChartsData] = useState(null);
  const [timeSpentOnAssignmentData, setTimeSpentOnAssignmentData] = useState(
    []
  );
  const [instructorsName, setInstructorsName] = useState("");
  const [courseAssignments, setCourseAssignments] = useState([]);

  const userIds = useMemo(() => {
    return Object.values(users)
      .filter((user) => user.course_role === "Student")
      .map((user) => user.course_user);
  }, [users]);

  const courseStudentsList = useMemo(() => {
    return Object.keys(users)
      .filter((key) => users[key].course_role === "Student")
      .map((key) => users[key]);
  }, [users]);

  const generateInitalUsersState = useCallback((users) => {
    return users.reduce((accumulator, current) => {
      const user = current.course_user;
      const selected = current.course_role === "Student";
      return {
        ...accumulator,
        [user]: { ...current, selected }
      };
    }, {});
  }, []);

  useEffect(() => {
    if (isEmpty(users)) return;
    function getTeacherUID(users) {
      for (const uid in users) {
        if (users[uid].course_role === "Teacher") {
          return uid;
        }
      }
      return null; // Return null if no teacher is found
    }

    const teacherUid = getTeacherUID(users);
    httpCallables
      .adminGetTeacherNameById({
        uid: teacherUid
      })
      .then((response) => {
        setInstructorsName(response.data);
      });
  }, [users]);

  useEffect(() => {
    httpCallables
      .adminGetCourseById({ courseId: Number(course_id) })
      .then((response) => {
        const currentCourse = response.data;
        setStart(new Date(currentCourse.course_start));
        setEnd(
          new Date(Math.min(new Date(currentCourse.course_end), new Date()))
        );
        setCourse(currentCourse);
      });
  }, [course_id]);

  useEffect(() => {
    if (course) {
      // Call both week and task data simultaneously
      const getWeekDataPromise = httpCallables.adminGetStats({
        course_id: Number(course_id),
        start,
        end,
        type: CHARTS_VIEW_TYPE.WEEK.toUpperCase()
      });

      const getTaskDataPromise = httpCallables.adminGetStats({
        course_id: Number(course_id),
        start,
        end,
        type: CHARTS_VIEW_TYPE.TASK.toUpperCase()
      });

      // Execute both promises concurrently
      Promise.all([getWeekDataPromise, getTaskDataPromise])
        .then(([weekResponse, taskResponse]) => {
          if (weekResponse.data.success && taskResponse.data.success) {
            setTotalSubmissions(
              weekResponse.data.payload.SUBMISSIONS_STATUS.totalSubmissions
            );
            const weekData = getWeekChartsDataFromApiResponse(
              weekResponse.data.payload
            );
            const taskData = getTaskChartsDataFromApiResponse(
              taskResponse.data.payload
            );

            const timePerStep = {
              HIGHLIGHT_TIME: taskResponse.data.payload.HIGHLIGHT_TIME,
              REVIEW_TIME: taskResponse.data.payload.REVIEW_TIME,
              ANSWER_TIME: taskResponse.data.payload.ANSWER_TIME
            };
            setCourseAssignments(
              taskResponse.data.payload.PUBLISHED_TASKS.tasks
            );
            setTimeSpentOnAssignmentData(timePerStep);
            setWeekChartsData(weekData);
            setTaskChartsData(taskData);
          } else {
            dispatch(
              addSnackbarItem({
                intlId: "error.readCourseUsersFailed",
                intlDefaultMessage:
                  "There was a problem getting the course information. Please check your connection and try again",
                id: uuid()
              })
            );
            if (!weekResponse.data.success) {
              captureException(
                weekResponse.data.error,
                `Failed to get week Stats`
              );
            }
            if (!taskResponse.data.success) {
              captureException(
                taskResponse.data.error,
                `Failed to get task Stats`
              );
            }
          }
        })
        .catch((error) => {
          // Handle any errors that occurred during the promises
          console.error("Error fetching data:", error);
        });
    }
  }, [start, end, course_id, course, dispatch]);

  useEffect(() => {
    httpCallables
      .readCourseUsers({ course_id: Number(course_id) })
      .then(({ data }) => {
        if (data.success) {
          const { users } = data.payload;
          dispatchUsers({
            type: "set",
            payload: generateInitalUsersState(users)
          });
        } else {
          const { error } = data.payload;
          dispatch(
            addSnackbarItem({
              intlId: "error.readCourseUsersFailed",
              intlDefaultMessage:
                "There was a problem getting the course information. Please check your connection and try again",
              id: uuid()
            })
          );
          captureException(error, `Failed to get course users`);
        }
      });
  }, [course_id, dispatch, generateInitalUsersState]);

  useEffect(() => {
    if (view === CHARTS_VIEW_TYPE.WEEK && weekChartData) {
      setChartsData(weekChartData);
    } else if (view === CHARTS_VIEW_TYPE.TASK && taskChartData) {
      setChartsData(taskChartData);
    }
  }, [view, taskChartData, weekChartData]);

  const handleViewChange = useCallback(
    (event) => {
      setView(event.target.value);
    },
    [setView]
  );
  const renderStats = () => {
    if (chartsData) {
      return (
        <Grid
          component={"section"}
          container
          spacing={3}
          alignItems={"flex-start"}
          className={classes.chartsSection}>
          <Grid item xs={6}>
            <PieChart
              key={chartsData[CHART_NAMES.ACADEMIC_COACH].title}
              title={chartsData[CHART_NAMES.ACADEMIC_COACH].title}
              footer={chartsData[CHART_NAMES.ACADEMIC_COACH].footer}
              data={chartsData[CHART_NAMES.ACADEMIC_COACH].data}
              height={432}
              width={488}
            />
          </Grid>
          <Grid item xs={6}>
            <PieChart
              key={chartsData[CHART_NAMES.GUIDED_READING].title} // FIXME
              title={chartsData[CHART_NAMES.GUIDED_READING].title}
              footer={chartsData[CHART_NAMES.GUIDED_READING].footer}
              data={chartsData[CHART_NAMES.GUIDED_READING].data}
              height={432}
              width={488}
            />
          </Grid>
          <Grid item xs={12}>
            <StackedPieChartBody
              data={chartsData[CHART_NAMES.SUBMISSIONS_STATUS]}
              height={432}
              width="55%"
              totalSubmissions={totalSubmissions}
              chartType={view}
            />
          </Grid>
          <Grid item xs={12}>
            <TimeOnAssignmentReport
              data={timeSpentOnAssignmentData}
              courseAssignments={courseAssignments}
              courseStudents={courseStudentsList}
            />
          </Grid>
          <ActivityReportGraphs
            chartsData={chartsData}
            totalUsers={courseStudentsList.length}
            dataPlotType={view}
          />
        </Grid>
      );
    } else return <PangeaSpinner />;
  };

  const renderPage = () => (
    <ScrollBox>
      <Box
        sx={{
          display: "flex",
          flexFlow: "row nowrap",
          alignSelf: "center",
          justifyItems: "center",
          justifyContent: "flex-start"
        }}>
        <Box className={classes.dateRangeSelection}>
          <DateRangeSelection
            start={start}
            setStart={setStart}
            end={end}
            setEnd={setEnd}
            maxDate={new Date()}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexFlow: "row nowrap",
            alignSelf: "center",
            justifyItems: "center",
            width: "250px"
          }}>
          <FormControl sx={{ minWidth: "150px" }}>
            <InputLabel id="view-select-label">View</InputLabel>
            <Select
              labelid="view-select-label"
              id="view-select"
              value={view}
              onChange={handleViewChange}>
              <MenuItem value={CHARTS_VIEW_TYPE.WEEK}>Week View</MenuItem>
              <MenuItem value={CHARTS_VIEW_TYPE.TASK}>Task View</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box sx={{ height: "100px", width: "100%", paddingInlineStart: "74px" }}>
        <Typography variant="h5">Course name: {course?.name}</Typography>
        <Typography variant="h6" sx={{ paddingBlockStart: "8px" }}>
          Instructor: {instructorsName}
        </Typography>
      </Box>

      <Box className={classes.container}>
        <WidgetHeader title="Users" />
        <UsersTable
          users={users}
          dispatchUsers={dispatchUsers}
          start={start}
          end={end}
        />
      </Box>
      {renderStats()}
      <Box className={classes.container}>
        <Grid className={classes.engagementChart} item xs={12}>
          <EngagementChart
            title={"Engagement"}
            course_id={course_id}
            users={userIds}
            start={start}
            end={end}
            includeClass={true}
            includeTypes={[
              "ENGAGEMENT",
              "SESSIONS",
              "READING_SESSIONS",
              "WRITING_SESSIONS",
              "COMMENTS_CREATED",
              "CITATIONS_CREATED",
              "QUESTIONS_CREATED"
            ]}
          />
        </Grid>
      </Box>
    </ScrollBox>
  );

  return course ? renderPage() : <PangeaSpinner />;
}
