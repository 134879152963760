// Dependencies
import React, { useEffect, useState } from "react";
import { firebaseFunctions, httpCallables } from "../../firebase";
import { useHistory } from "react-router-dom";
import { useQuery } from "../../hooks";

// Redux dependencies
import { useDispatch } from "react-redux";
import { setBreadcrumbs } from "../../redux/readerActionsSlice";

// Components
import PangeaSpinner from "../SharedComponents/PangeaSpinner";
import CoursesList from "./CoursesList";
import FeatureFlags from "./FeatureFlags";
import Playground from "./playground/Playground";

import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Drawer,
  ListItemIcon
} from "@mui/material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import SchoolIcon from "@mui/icons-material/School";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import FlagIcon from "@mui/icons-material/Flag";
import InstitutionsList from "./InstitutionsList";

function Admin() {
  //Hooks
  const { page } = useQuery();
  const history = useHistory();
  const dispatch = useDispatch();

  //Ephemeral State
  const [courses, setCourses] = useState(null);
  const [institutions, setInstitutions] = useState([]);
  const [displayedPage, setDisplayedPage] = useState(page ?? "courses");

  //Variables
  const pages = [
    { key: "institutions", name: "Institutions", icon: <AccountBalanceIcon /> },
    { key: "courses", name: "Courses", icon: <SchoolIcon /> },
    { key: "playground", name: "AI Playground", icon: <SmartToyIcon /> },
    { key: "featureFlags", name: "Feature Flags", icon: <FlagIcon /> }
  ];

  //Behavior
  useEffect(() => {
    const parts = [{ text: "Admin", url: "/admin" }];
    dispatch(setBreadcrumbs({ breadcrumbs: parts }));
  }, []);

  useEffect(() => {
    const getAllCourses = firebaseFunctions.httpsCallable(
      "adminFunctions-getAllCourses"
    );

    getAllCourses().then(({ data }) => {
      const sortedCourses = data.sort((a, b) => b.id - a.id);
      setCourses(sortedCourses);
    });
  }, []);

  useEffect(() => {
    httpCallables
      .institutionsFunctions({ func_name: "getAllInstitutions" })
      .then(({ data }) => {
        setInstitutions(data);
      });
  }, []);

  const renderContent = () => {
    switch (displayedPage) {
      case "institutions":
        return <InstitutionsList institutions={institutions} />;
      case "courses":
        return (
          <CoursesList
            courses={courses}
            setCourses={setCourses}
            institutions={institutions}
          />
        );
      case "playground":
        return <Playground />;
      case "featureFlags":
        return <FeatureFlags courses={courses} institutions={institutions} />;
    }
  };

  //Render
  if (!courses) {
    return <PangeaSpinner />;
  } else
    return (
      <Box sx={{ width: "100%", display: "flex" }}>
        <Drawer
          sx={{
            width: 240,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: 240,
              boxSizing: "border-box"
            }
          }}
          variant="permanent"
          anchor="left">
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ marginTop: "160px", marginLeft: "16px" }}>
            Admin
          </Typography>
          <List>
            {pages.map((page, index) => (
              <ListItem key={page.key}>
                <ListItemButton
                  onClick={() => {
                    setDisplayedPage(page.key);
                    history.push(`admin?page=${page.key}`);
                  }}>
                  <ListItemIcon>{page.icon}</ListItemIcon>
                  <ListItemText primary={page.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
        <Box sx={{ width: "calc(100% - 240px)" }}>{renderContent()}</Box>
      </Box>
    );
}

export default Admin;
