// Dependencies
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocalStorage } from "./hooks/useLocalStorage";

// Components
import { FormattedMessage } from "react-intl";

// Material UI
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogActions
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

export default function VersionUpdateDialog() {
  const firestoreAppVersion = useSelector(
    (state) => state.firestore.data?.defaultConfig?.VERSION
  );

  const [remoteConfigRaw, setRemoteConfigRaw] = useLocalStorage(
    "conf.lastRemoteConfig",
    {}
  );

  const shouldShow = useMemo(() => {
    let remoteConfigParsed =
      remoteConfigRaw && typeof remoteConfigRaw === "string"
        ? JSON.parse(remoteConfigRaw)
        : remoteConfigRaw;

    const retVal =
      remoteConfigRaw &&
      remoteConfigParsed?.version &&
      firestoreAppVersion &&
      firestoreAppVersion !== remoteConfigParsed?.version;
    return retVal || false;
  }, [remoteConfigRaw, firestoreAppVersion]);

  const updateRemoteConfig = () => {
    const remoteConfigParsed =
      remoteConfigRaw && typeof remoteConfigRaw === "string"
        ? JSON.parse(remoteConfigRaw)
        : remoteConfigRaw;

    const payload = {
      recaptcha_key: remoteConfigParsed.recaptcha_key,
      version: firestoreAppVersion
    };
    setRemoteConfigRaw(payload);
  };

  // Behavior
  return (
    <Dialog open={shouldShow} onClose={updateRemoteConfig}>
      <DialogContent>
        <Typography>
          <FormattedMessage
            id="upgrade.msg"
            defaultMessage="A new Alethea version is available, click ‘Reload’ to update"
          />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          aria-label={"Reload"}
          sx={{
            backgroundColor: "#168fee",
            color: "#ffffff",
            textTransform: "none",
            "&:hover": {
              background: "#1172be"
            }
          }}
          onClick={() => {
            updateRemoteConfig();
            window.location.reload(true);
          }}>
          <FormattedMessage id="upgrade.reload" defaultMessage="Reload" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
