import React, { useEffect, useState } from "react";

import { firebaseFunctions } from "../../firebase";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import {
  //Fab,
  Box,
  Button,
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Chip
} from "@mui/material";
import HandleTextModal from "../library/HandleTextModal";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100w",
    height: "100vh"
    // background:'white',
  },
  dialog: {
    backgroundColot: "red"
  },
  tableContainer: {
    width: "80%",
    marginRight: "auto",
    // backgroundColor:'white',
    marginBottom: "24px"
  },
  selectEmpty: {
    width: "80%"
  },
  tableHead: {
    fontWeight: "800"
  }
}));

function CourseTexts(props) {
  const classes = useStyles();

  const [texts, setTexts] = useState([]);
  const [openTexts, setOpenTexts] = useState(false);

  useEffect(() => {
    const getAllCourseTexts = firebaseFunctions.httpsCallable(
      "adminFunctions-getAllCourseTexts1"
    );

    getAllCourseTexts({ course: props.courseId }).then((response) => {
      let rows = response.data;
      setTexts(rows);
    });
  }, [props.courseId]);

  return (
    <Box>
      <TableContainer
        className={classes.tableContainer}
        // component={Paper}
      >
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={clsx(classes.cell, classes.tableHead)}>
                Author
              </TableCell>
              <TableCell className={clsx(classes.cell, classes.tableHead)}>
                Title
              </TableCell>
              <TableCell className={clsx(classes.cell, classes.tableHead)}>
                Category
              </TableCell>
              <TableCell className={clsx(classes.cell, classes.tableHead)}>
                Chapters
              </TableCell>
              <TableCell className={clsx(classes.cell, classes.tableHead)}>
                Pages
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {texts.map((row) => {
              return (
                <TableRow key={row.author + row.id}>
                  <TableCell
                    className={classes.cell}
                    component="th"
                    scope="row">
                    {row.author}
                  </TableCell>
                  <TableCell className={classes.cell} align="left">
                    <a href={row.downloadUrl} download>
                      {row.name}
                    </a>
                  </TableCell>
                  <TableCell className={classes.cell} align="left">
                    {row.categories &&
                      row.categories.split(",").map((item, index) => {
                        return <Chip key={index} label={item} />;
                      })}
                  </TableCell>
                  <TableCell className={classes.cell} align="left">
                    {row.file_location}
                  </TableCell>
                  <TableCell className={classes.cell} align="left">
                    {Number(row.pages) ? row.pages : ""}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Button
        variant="contained"
        color="primary"
        aria-label="Add a new text"
        onClick={() => {
          setOpenTexts("Add");
        }}>
        Add Text
      </Button>
      {openTexts && (
        <HandleTextModal openTexts={openTexts} setOpenTexts={setOpenTexts} />
      )}
    </Box>
  );
}

export default CourseTexts;
