import FlexSearch from "flexsearch";
import ePub from "epubjs";
import { stemmer } from "stemmer";
import { INTERACTION_SUBTYPES } from "../../../consts";

const EpubCFI = new ePub.CFI();

export function mapConcepts(question, answer) {
  if (!question || !question.concepts || !answer || !answer.content) return [];
  // Naive solution: We are assuming that if there are two words in hebrew, than the whole content should be matched in RTL
  const regex = /[יכלמנסעפצקרשתאבגדהוזחט]+\s+[אבגדהוזחטיכלמנסעפצקרשת]+/g;
  const isRtl = answer.content.search(regex) >= 0;

  let flexIndex = new FlexSearch({
    filter: [
      /*
      "a",
      "about",
      "above",
      "after",
      "again",
      "against",
      "all",
      "also",
      "am",
      "an",
      "and",
      "any",
      "are",
      "aren't",
      "as",
      "at",
      //"back",
      "be",
      "because",
      "been",
      "before",
      "being",
      "below",
      //"between",
      "both",
      "but",
      "by",
      "can",
      "cannot",
      "can't",
      "come",
      "could",
      "couldn't",
      //"day",
      "did",
      "didn't",
      "do",
      "does",
      "doesn't",
      "doing",
      "dont",
      "down",
      "during",
      "each",
      "even",
      "few",
      "first",
      "for",
      "from",
      "further",
      "get",
      //"give",
      "go",
      //"good",
      "had",
      "hadn't",
      "has",
      "hasn't",
      "have",
      "haven't",
      "having",
      "he",
      "hed",
      //"hell",
      "her",
      "here",
      "here's",
      "hers",
      "herself",
      "hes",
      "him",
      "himself",
      "his",
      "how",
      "how's",
      "i",
      "id",
      "if",
      "ill",
      "im",
      "in",
      "into",
      "is",
      "isn't",
      "it",
      "it's",
      "itself",
      "i've",
      "just",
      "know",
      "let's",
      "like",
      //"look",
      "make",
      "me",
      "more",
      "most",
      "mustn't",
      "my",
      "myself",
      "new",
      "no",
      "nor",
      "not",
      "now",
      "of",
      "off",
      "on",
      "once",
      //"one",
      "only",
      "or",
      "other",
      "ought",
      "our",
      "our's",
      "ourselves",
      "out",
      "over",
      "own",
      //"people",
      "same",
      "say",
      "see",
      "shan't",
      "she",
      "she'd",
      "shell",
      "shes",
      "should",
      "shouldn't",
      "so",
      "some",
      "such",
      //"take",
      "than",
      "that",
      "that's",
      "the",
      "their",
      "theirs",
      "them",
      "themselves",
      "then",
      "there",
      "there's",
      "these",
      "they",
      "they'd",
      "they'll",
      "they're",
      "they've",
      //"think",
      "this",
      "those",
      "through",
      "time",
      "to",
      "too",
      //"two",
      //"under",
      "until",
      "up",
      "us",
      //"use",
      "very",
      "want",
      "was",
      "wasn't",
      "way",
      "we",
      "wed",
      "well",
      "were",
      "weren't",
      "we've",
      "what",
      "what's",
      "when",
      "when's",
      "where",
      "where's",
      "which",
      "while",
      "who",
      "whom",
      "who's",
      "why",
      "why's",
      "will",
      "with",
      "won't",
      //"work",
      "would",
      "wouldn't",
      //"year",
      "you",
      "you'd",
      "you'll",
      "your",
      "you're",
      "your's",
      "yourself",
      "yourselves",
      "you've"*/
    ],

    /**
     * @type {Object<string, string>}
     * @export
     */

    stemmer: function (value) {
      // apply some replacements
      // ...

      return stemmer(value);
    },

    encode: isRtl ? false : "balance",
    rtl: isRtl,
    split: isRtl ? /\s+/ : /\W+/
  });

  flexIndex.add(answer.id, answer.content);

  const results = question.concepts.map((concept) => {
    const results = flexIndex.search(stemmer(concept));
    const exactResults = flexIndex.search(concept);
    return {
      concept: concept,
      found: results.includes(answer.id) || exactResults.includes(answer.id)
    };
  });

  return results;
}

export function calculateFindInTextScore(question, highlights) {
  let tempScore = 0;

  if (!highlights || !highlights.length) return 0;
  if (!question.quotes || !question.quotes.length) return 1;
  let teacherSortedQuotes = [...question.quotes].sort(function (a, b) {
    return EpubCFI.compare(a.cfi, b.cfi);
  });

  let studentSorted = [...highlights].sort(function (a, b) {
    return EpubCFI.compare(a.cfi, b.cfi);
  });

  let studentInd = 0;
  let teacherInd = 0;

  while (
    studentInd < studentSorted.length &&
    teacherInd < teacherSortedQuotes.length
  ) {
    let cfiParts = studentSorted[studentInd].cfi.split(","); // cfiBase:  cfiParts[0]
    let studentStartCfi = cfiParts[0] + cfiParts[1] + ")"; // start: 'epubcfi(/6/4[chap01ref]!/4[body01]/10[para05]/2/1:1)'
    let studentEndCfi = cfiParts[0] + cfiParts[2];

    cfiParts = teacherSortedQuotes[teacherInd].cfi.split(","); // cfiBase:  cfiParts[0]
    let teacherStartCfi = cfiParts[0] + cfiParts[1] + ")"; // start: 'epubcfi(/6/4[chap01ref]!/4[body01]/10[para05]/2/1:1)'
    let teacherEndCfi = cfiParts[0] + cfiParts[2];
    if (EpubCFI.compare(studentEndCfi, teacherStartCfi) <= 0) {
      studentInd++;
    } else if (EpubCFI.compare(teacherEndCfi, studentStartCfi) <= 0) {
      teacherInd++;
    } else {
      let needle = studentSorted[studentInd].content;
      let haystack = teacherSortedQuotes[teacherInd].content;
      if (haystack.length < needle.length) {
        haystack = needle;
        needle = teacherSortedQuotes[teacherInd].content;
      }
      let retVal = subCompare(needle, haystack);
      if (retVal.found) {
        tempScore += retVal.substring.length;
      }

      if (EpubCFI.compare(studentEndCfi, teacherEndCfi) >= 0) {
        studentInd++;
      } else {
        teacherInd++;
      }
    }
  }
  let totalTeacher = teacherSortedQuotes.reduce(
    (acc, curr) => acc + curr.content.length,
    0
  );

  return (1.0 * tempScore) / totalTeacher;
}

function subCompare(needle, haystack, min_substring_length = 1) {
  // Search possible substrings from largest to smallest:
  for (var i = needle.length; i >= min_substring_length; i--) {
    for (var j = 0; j <= needle.length - i; j++) {
      var substring = needle.substr(j, i);
      var k = haystack.indexOf(substring);
      if (k !== -1) {
        return {
          found: 1,
          substring: substring,
          needleIndex: j,
          haystackIndex: k
        };
      }
    }
  }
  return {
    found: 0
  };
}

const getMatchScore = (quesion, match) => {
  if (match === 0) return 0;
  let factored = match;
  if (match > 0.7) {
    factored = 1;
  } else if (match > 0.6) {
    factored = factored * 1.25;
  } else {
    factored = Math.min(0.6, factored * 1.55);
  }
  return Math.ceil(factored * parseFloat(quesion.points));
};

export const calculateQuestionPoints = (question, answer, highlights) => {
  if (!question.points) return;
  const points = Math.round(Number(question.points));
  if (Number.isNaN(points) || points === 0) return;

  let calc = 0;
  const highlightMatch = calculateFindInTextScore(question, highlights);
  if (question.interaction_subtype === INTERACTION_SUBTYPES.FIND_IN_TEXT) {
    calc = getMatchScore(question, highlightMatch);
  } else if (question.interaction_subtype === INTERACTION_SUBTYPES.OPEN_ENDED) {
    const conceptMapping = mapConcepts(question, answer);
    let questionPart;
    const foundConcepts = conceptMapping.filter(
      (concept) => concept.found
    ).length;

    if (conceptMapping.length === 0) questionPart = points;
    else if (foundConcepts === 0) questionPart = 0;
    else {
      questionPart = Math.ceil(
        (conceptMapping.filter((concept) => concept.found).length /
          conceptMapping.length) *
          points
      );
    }
    if (question.includeCitation && highlights.length) {
      let quotePart = getMatchScore(question, highlightMatch);
      calc = 0.5 * quotePart + 0.5 * questionPart;
    } else calc = questionPart;
  } else if (
    question.interaction_subtype === INTERACTION_SUBTYPES.MULTI_CHOICE
  ) {
    let questionPart =
      Number(question.shouldSelect) === Number(answer.choice)
        ? parseInt(points)
        : 0;
    if (question.includeCitation && highlights.length) {
      let quotePart = getMatchScore(question, highlightMatch);
      calc = 0.5 * quotePart + 0.5 * questionPart;
    } else calc = questionPart;
  }
  // console.log("calculateQuestionPoints", calc);
  return calc;
};

export const getWordCount = (content) => {
  const regex = /(?:\r\n|\r|\n)/g; // new line, carriage return, line feed
  const cleanString = content.replace(regex, " ").trim(); // replace above characters w/ space
  const wordArray = cleanString.match(/\S+/g); // matches words according to whitespace
  return wordArray && wordArray.length;
};

export const generateRandomKey = (length = 5) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};
