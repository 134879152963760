// Dependancies
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

import VisibilityIcon from "@mui/icons-material/Visibility";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import {
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  IconButton,
  Box,
  Tooltip,
  Typography,
  Button
} from "@mui/material";

import CreateCourseDialog from "./CreateCourseDialog";
import CreateNewIntitutionDialog from "./CreateNewIntitutionDialog";
import { ScrollBox } from "../SharedComponents";

function CoursesAdmin({ courses, setCourses, institutions }) {
  const history = useHistory();
  const intl = useIntl();

  const [newCourseDialogVisible, setNewCourseDialogVisible] = useState(false);
  const [newInstitutionDialogVisible, setNewInstitutionDialogVisible] =
    useState(false);

  return (
    <ScrollBox>
      <Box sx={{ padding: "40px" }}>
        <CreateCourseDialog
          courses={courses}
          setCourses={setCourses}
          newCourseDialogVisible={newCourseDialogVisible}
          setNewCourseDialogVisible={setNewCourseDialogVisible}
        />
        <CreateNewIntitutionDialog
          newInstitutionDialogVisible={newInstitutionDialogVisible}
          setNewInstitutionDialogVisible={setNewInstitutionDialogVisible}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px"
          }}>
          <Typography variant="h4">
            {intl.formatMessage({
              id: "admin.courseAdmin",
              defaultMessage: "Courses"
            })}
          </Typography>
          <Box>
            <Button
              variant="contained"
              color="primary"
              sx={{ marginRight: 2 }}
              onClick={() => {
                setNewInstitutionDialogVisible(true);
              }}>
              Create institution
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setNewCourseDialogVisible(true);
              }}>
              Create Course
            </Button>
          </Box>
        </Box>
        <TableContainer>
          <Table aria-label="course table">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Year</TableCell>
                <TableCell>Semester</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Institution</TableCell>
                <TableCell>Course Time Zone</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {courses.map((course) => (
                <TableRow key={course.id}>
                  <TableCell align="left">{course.id}</TableCell>
                  <TableCell component="th" scope="row">
                    {course.academic_year}
                  </TableCell>
                  <TableCell align="left">{course.semester}</TableCell>
                  <TableCell
                    sx={{
                      maxWidth: "200px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}
                    align="left">
                    <Tooltip title={course.name}>
                      <Box>{course.name}</Box>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    sx={{
                      maxWidth: "200px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}
                    align="left">
                    {institutions
                      .filter(
                        (institution) =>
                          institution.id === course.institution_id
                      )
                      .map((filteredInstitution) => filteredInstitution.name)}
                  </TableCell>
                  <TableCell
                    sx={{
                      maxWidth: "200px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}
                    align="left">
                    {course.timezone}
                  </TableCell>
                  <TableCell align="left">
                    <Box sx={{ display: "flex" }}>
                      <IconButton
                        onClick={() => {
                          history.push(
                            `admin/CourseActivityReport?course_id=${course.id}`
                          );
                        }}
                        size="large">
                        <InsertChartIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          history.push(`admin/course?course_id=${course.id}`);
                        }}
                        size="large">
                        <VisibilityIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </ScrollBox>
  );
}

CoursesAdmin.propTypes = {
  courses: PropTypes.array.isRequired,
  setCourses: PropTypes.func.isRequired,
  institutions: PropTypes.array.isRequired
};

export default CoursesAdmin;
