import React, { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export const ZendeskLabels = ({ searchTerm = "", labels = [] }) => {
  // Hooks
  const { pathname } = useLocation();

  // Global State
  const currentRole = useSelector(
    (state) =>
      state.user.userProfile.selectedCourse?.course_role?.toLowerCase() || ""
  );
  const step = useSelector((state) => state.task.step);
  const grStep = useSelector((state) => state.gr.stage);

  const zendeskLabels = useMemo(() => {
    let baseLabel = pathname.replace(/\//g, "::");
    const isTasksPage = pathname === "/tasks";
    const isNewTask = pathname === "/tasks/new";
    const isSpecificPath = ["/task", "/reader"].includes(pathname);

    if (isNewTask) {
      baseLabel = "new::task";
      return [`${baseLabel}`, ...labels];
    }
    if (isTasksPage) {
      return [`${currentRole}${baseLabel}`, ...labels];
    }
    if (isSpecificPath && !isNewTask) {
      const stepByLocation = pathname === "/reader" ? grStep : step;
      return [`${currentRole}${baseLabel}::step::${stepByLocation}`, ...labels];
    }

    return [`${currentRole}${baseLabel}`, ...labels];
  }, [currentRole, pathname, labels, step, grStep]);

  useEffect(() => {
    const suggestions = {
      url: false,
      search: searchTerm || true,
      labels: zendeskLabels
    };

    if (window?.zE) {
      window.zE("webWidget", "helpCenter:setSuggestions", suggestions);
    }
  }, [zendeskLabels, searchTerm]);

  return null;
};
