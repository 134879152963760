import React, { useEffect, useState } from "react";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import ClassChip from "./ClassChip";
import { format, parseISO } from "date-fns";
import academicFields from "./AcademicFields";
import { firebaseFunctions } from "../../../../firebase";
import { useDispatch, useSelector } from "react-redux";
import { enqueueFlashMessage } from "../../../../redux/userSlice";
import { selectAlertsDuration } from "../../../../redux/firestoreSelectors";

const InstitutionCourseOverview = ({ course, fetchData }) => {
  const dispatch = useDispatch();
  const alertsDuration = useSelector((state) => selectAlertsDuration(state));
  const [school, setSchool] = useState(
    course?.metadata?.internal
      ? {
          field: "Sandbox",
          discipline: "Alethea"
        }
      : null
  );
  // console.log("school", course?.metadata?.internal, school);

  useEffect(() => {
    if (course.metadata?.school) {
      const field = academicFields.find(
        (f) => f.field === course.metadata.school
      );
      setSchool(field);
    }
  }, [course.metadata]);

  const boxes = [
    {
      title: "Enrolled Students",
      value: course.enrolledStudents
    },
    {
      title: "Active Students",
      value: course.activeStudents
    },
    {
      title: "Published Assignments",
      value: course.publishedAssignments
    },
    {
      title: "Submission Rate",
      value: `${Math.round(course.submissionRate * 100)}%`
    },
    {
      title: "Feedback",
      value: `${Number(course.feedback.toFixed(2))}%`
    },
    {
      title: "Engagment per Assignment",
      value: `${course.engagement}m`
    }
  ];

  const composeMetadata = (school) => {
    const { school: _, ...rest } = course.metadata ?? {};
    if (school) {
      return school.field === "Sandbox"
        ? { ...rest, internal: true }
        : { ...rest, school: school.field };
    } else {
      return rest.internal ? { ...rest, internal: false } : rest;
    }
  };

  const handleSchoolSetting = (event, school) => {
    setSchool(school);
    const metadata = composeMetadata(school);
    course.metadata = metadata;
    console.log("handleSchoolSetting", course, school, metadata);
    firebaseFunctions
      .httpsCallable("adminFunctions-updateCourse")({
        courseId: course.id,
        fields: { metadata }
      })
      .then((response) => {
        if (response.data.success) {
          if (school?.field === "Sandbox" || (!school && !metadata.internal)) {
            fetchData();
          }
          dispatch(
            enqueueFlashMessage({
              message: "Course updated",
              duration: alertsDuration
            })
          );
        }
      });
  };

  return (
    <Box
      sx={{
        width: "100%",
        padding: "32px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "border.main",
        borderRadius: "5px",
        backgroundColor: "white"
      }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "24px"
        }}>
        <Box>
          <Typography variant="body1">{course.name}</Typography>
          <Box sx={{ marginBottom: "16px" }}>
            {course.instructors.map((instructor, index) => {
              return (
                <Typography
                  key={instructor.course_user}
                  variant="body2"
                  component="span"
                  sx={{ color: "text.secondary" }}>
                  {instructor.users.name}
                  {index < course.instructors.length - 1 ? ", " : ""}
                </Typography>
              );
            })}
          </Box>
          <ClassChip rating={course.rating} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-end"
          }}>
          <Autocomplete
            variant="outlined"
            value={school}
            onChange={handleSchoolSetting}
            isOptionEqualToValue={(option, value) => {
              if (value === null && option === "") return true;
              return option.field === value?.field;
            }}
            options={academicFields}
            groupBy={(option) => option.discipline}
            getOptionLabel={(option) => option?.field ?? ""}
            sx={{ width: "300px", marginBottom: "16px" }}
            renderInput={(params) => (
              <TextField {...params} label="School or department" />
            )}
          />
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {format(parseISO(course.courseStart), "MMMM d yyyy")} -{" "}
            {format(parseISO(course.courseEnd), "MMMM d yyyy")}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between"
        }}>
        {boxes.map((item, index) => (
          <Box
            key={index}
            sx={{
              flex: "1 1 calc(16.66% - 16px)",
              marginInlineEnd: index % 6 !== 5 ? "16px" : 0,
              height: "130px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "border.main",
              borderRadius: "5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "background.paper"
            }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexFlow: "column",
                height: "100%",
                paddingTop: "18px",
                paddingBottom: "18px"
              }}>
              <Typography variant="p" sx={{}}>
                {item.title}
              </Typography>
              <Typography variant="h4">{item.value}</Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default InstitutionCourseOverview;
