// Dependencies
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import clsx from "clsx";
import { userAPI } from "../../../api";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { enqueueFlashMessage, undo } from "../../../redux/userSlice";

// Components
import AssignmentQuestion from "./AssignmentQuestion";
import NavigationButtons from "./NavigationButtons";
import {
  QUESTION,
  TASK,
  Question,
  USER_ACTIONS,
  INTERACTION_SUBTYPES
} from "../../../consts";

import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  FormControl,
  TextField,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Skeleton,
  Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import { selectAlertsDuration } from "../../../redux/firestoreSelectors";
import AssignmentAssistantCard from "./AssignmentAssistantCard";
import AIIcon from "../../SharedComponents/AIIcon";
import { setTaskCreationStates } from "../../../redux/tasksSlice";
import { httpCallables } from "../../../firebase";
import AiQuestionsPlaceholder from "./AiQuestionsPlaceholder";

//Styles
const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: 0
  },
  formRow: {
    marginBlockEnd: "30px",
    display: "flex",
    flexFlow: "row nowrap",
    "& .MuiFormControl-root": {
      flex: "1 1 0",
      marginInlineEnd: "8px"
    },
    "& .MuiFormControl-root:last-child": {
      marginInlineEnd: "0"
    },
    "& $taskTitle": {
      flexGrow: "2"
    }
  },
  // Dont delete, .taskTitle is referenced somewhere else
  taskTitle: {}
}));

export default function CreateAssignmentAssignment({
  task,
  questions,
  setQuestions,
  readOnly = false,
  text,
  taskType,
  taskTitle,
  setTaskTitle,
  taskDescription,
  setTaskDescription,
  setActiveStep,
  activeStep,
  publishTask,
  saveTask,
  botConfig,
  setBotConfig
}) {
  //Hooks
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);
  const questionsRef = useRef([]);
  const questionsContainerRef = useRef(null);

  //Redux State
  const shouldUndo = useSelector((state) => state.user.undo);
  const alertsDuration = useSelector((state) => selectAlertsDuration(state));

  //Ephemeral State
  const [undoData, setUndoData] = useState(null);
  const [botCardexpanded, setBotCardexpanded] = useState(
    botConfig?.assistant ?? false
  );
  const [botAssistant, setBotAssistant] = useState(botConfig.assistant);
  const [selectedDifficulty, setSelectedDifficulty] = useState([
    String(botConfig.difficulty)
  ]);
  const [readingFocus, setReadingFocus] = useState(botConfig.readingFocus);
  const [selectedQuestionKind, setSelectedQuestionKind] = useState(
    botConfig.kind
  );
  const [botGenerating, setBotGenerating] = useState(false);
  const [questionRegenerating, setQuestionRegenerating] = useState(false);
  const [addQuestionRegenerating, setAddQuestionRegenerating] = useState(false);
  const [botConfigDirty, setBotConfigDirty] = useState(false);
  const [showRegeneratingDialog, setShowRegeneratingDialog] = useState(false);
  const [regeneratingDialogContent, setRegeneratingDialogContent] = useState(
    {}
  );
  const [visibleQuestions, setVisibleQuestions] = useState([]);
  const [aiQuestionsPlaceholder, setAiQuestionsPlaceholder] = useState(0);

  //Variables
  const titleValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.enterTitle",
    defaultMessage: "Enter a task title"
  });
  const questionValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.enterQuestion",
    defaultMessage: "Add a question"
  });
  const formulationValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.enterFormulation",
    defaultMessage: "Add question text"
  });
  const questionTypeValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.selectType",
    defaultMessage: `Select question type for question`
  });

  const optionsValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.optionsValue",
    defaultMessage: "Multiple choice question must have more than one option "
  });
  const optionsChoiceValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.optionChoice",
    defaultMessage: "Please select correct answer choice for question"
  });

  const aiQuestions = questions.filter((q) => q.aiQuestion);

  const fixQuestionsOrder = (currentQuestions) => {
    // set ordering to be correct whenver array changes - to avoid gaps etc.
    currentQuestions.sort((a, b) => (a.order || 0) - (b.order || 0));
    return currentQuestions;
  };

  //Behavior
  useEffect(() => {
    const undoDeleteQuestion = () => {
      if (undoData.type === "deleteQuestion") {
        let currentQuestions = [...questions];
        if (undoData.question.aiQuestion) {
          const deletedQuestionIndex = currentQuestions.findIndex(
            (q) => q.tempId === undoData.question.tempId
          );
          currentQuestions[deletedQuestionIndex].bot.active = true;
        } else {
          currentQuestions.splice(undoData.index, 0, undoData.question);
        }

        setQuestions(fixQuestionsOrder(currentQuestions));
        setUndoData(null);
      }
    };

    if (shouldUndo) {
      undoDeleteQuestion();
      dispatch(undo(false));
    }
  }, [shouldUndo, dispatch, undoData, questions, setQuestions]);

  useEffect(() => {
    const visibleQuestions = questions
      .filter((q) => !q.bot || q.bot.active)
      .sort((a, b) => (a.order || 0) - (b.order || 0));

    if (visibleQuestions.length > 0) setAiQuestionsPlaceholder(0);

    setVisibleQuestions(visibleQuestions);

    questionsContainerRef.current.style.minHeight = "0px";

    questionsRef.current = questionsRef.current.slice(
      0,
      visibleQuestions.length
    );
  }, [questions]);

  useEffect(() => {
    if (!visibleQuestions[visibleQuestions.length - 1]?.aiQuestion) {
      questionsRef.current[visibleQuestions.length - 1]?.scrollIntoView({
        behavior: "smooth"
      });
    }
  }, [visibleQuestions.length]);

  useLayoutEffect(() => {
    if (isFirstRender.current) {
      // Skip effect on initial render
      isFirstRender.current = false;
      return;
    }
    setBotConfig({
      assistant: botAssistant,
      kind: selectedQuestionKind,
      readingFocus: readingFocus,
      difficulty: Number(selectedDifficulty[0])
    });
    setBotConfigDirty(true);
  }, [botAssistant, selectedQuestionKind, readingFocus, selectedDifficulty]);

  const updateQuestions = (update, tempId) => {
    const updatedQuestion = questions.find((q) => q.tempId === tempId);
    const oldQuestion = { ...updatedQuestion };

    const updatedQuestions = questions.map((q) =>
      q.tempId === tempId ? { ...q, ...update } : q
    );

    if (updatedQuestion.aiQuestion) {
      userAPI.logAction({
        action_name: USER_ACTIONS.TASK_EDIT_AI_QUESTION,
        payload: {
          task_id: task?.id || null,
          old: oldQuestion,
          updated: update
        }
      });
    }
    // question.order = index; // FIXME
    setQuestions(updatedQuestions);
  };

  const addQuestion = () => {
    const newQuestion = new Question(
      QUESTION.OPEN_ENDED,
      visibleQuestions.length + 1,
      taskType
    );
    const orderedQuestions = questions.map((q) =>
      q.order >= newQuestion.order ? { ...q, order: q.order + 1 } : q
    );
    setQuestions([...orderedQuestions, newQuestion]);

    userAPI.logAction({
      action_name: USER_ACTIONS.TASK_ADD_MANUAL_QUESTION,
      payload: {
        task_id: task?.id || null,
        new: true,
        manual: true
      }
    });
  };

  const copyQuestion = (question) => {
    setQuestions([...questions, question]);
  };

  function deleteQuestion(tempId) {
    let currentQuestions = [...questions];
    const deletedQuestionIndex = currentQuestions.findIndex(
      (q) => q.tempId === tempId
    );
    const deletedQuestion = currentQuestions[deletedQuestionIndex];
    if (currentQuestions[deletedQuestionIndex].aiQuestion) {
      userAPI.logAction({
        action_name: USER_ACTIONS.TASK_DELETE_AI_QUESTION,
        payload: { task_id: task?.id || null }
      });
      if (!currentQuestions[deletedQuestionIndex].bot) {
        currentQuestions[deletedQuestionIndex].bot = {
          active: true,
          used: true
        };
      }
      currentQuestions[deletedQuestionIndex].bot.active = false;
    } else {
      currentQuestions.splice(deletedQuestionIndex, 1);
    }

    setUndoData({
      type: "deleteQuestion",
      question: deletedQuestion,
      index: deletedQuestionIndex
    });

    dispatch(
      enqueueFlashMessage({
        message: intl.formatMessage({
          id: "task.deletedQuestion",
          defaultMessage: "Deleted question"
        }),
        duration: alertsDuration,
        undoButton: true
      })
    );

    setQuestions(fixQuestionsOrder(currentQuestions));
  }

  function validateForm() {
    let message = [];
    // Check if there is a title
    if (!taskTitle) {
      message.push(titleValidationMessage);
    }
    // Check that there is at least one question
    if (
      taskType !== "peerReview" &&
      taskType !== "guidedReading" &&
      visibleQuestions.length === 0
    ) {
      message.push(questionValidationMessage);
    } else {
      if (taskType === "peerReview" || taskType === "guidedReading") {
        questions = questions.filter(
          (question) => question.content.trim() !== ""
        );
        setQuestions(questions);
      }
      // if there are questions, check that each have...
      visibleQuestions.forEach((question, index) => {
        // A question formulation
        if (
          taskType !== "peerReview" &&
          taskType !== "guidedReading" &&
          question.content.trim() === ""
        ) {
          message.push(`${formulationValidationMessage} ${index + 1}`);
        }
        // A type selected
        if (
          question.interaction_subtype === "" &&
          taskType !== "guidedReading"
        ) {
          message.push(`${questionTypeValidationMessage} ${index + 1}`);
        }
        if (question.interaction_subtype === "MULTI_CHOICE") {
          const options = question?.options.filter((q) => q.trim());
          if (!options || !options.length || options.length < 2) {
            message.push(`${optionsValidationMessage} ${index + 1}`);
          }
          if (
            !visibleQuestions ||
            !visibleQuestions[index] ||
            visibleQuestions[index].shouldSelect < 0 ||
            visibleQuestions[index].shouldSelect >=
              (question.options && question.options.length) ||
            typeof visibleQuestions[index].shouldSelect === "undefined"
          ) {
            message.push(`${optionsChoiceValidationMessage} ${index + 1}`);
          }
        }
      });
    }
    if (message.length === 0 || message === "") {
      return true;
    } else {
      dispatch(
        enqueueFlashMessage({
          message: message,
          duration: alertsDuration,
          severity: "error"
        })
      );
    }
  }

  const handleBotSwitch = (event) => {
    setBotAssistant(event.target.checked);
    dispatch(setTaskCreationStates("taskCreationBot", event.target.checked));
    setBotCardexpanded(event.target.checked);
  };

  const handleBotCardExpansion = (event, isExpanded) => {
    setBotCardexpanded(isExpanded);
  };

  const callCreateAssignmentApi = async () => {
    const usedQuestions = questions.filter(
      (q) => !q.aiQuestion || !q.bot || q.bot.used
    );
    const param = {
      func_params: {
        botType: "createAssignment",
        courseId: text.course_id,
        textId: text.id,
        selectedQuestionKind,
        readingFocus,
        taskTitle,
        taskDescription,
        difficulty: Number(selectedDifficulty[0]),
        questions: usedQuestions
      },
      func_name: "mentorInteraction"
    };
    return httpCallables.ementoringInteraction2(param).then(({ data }) => {
      const { success } = data;
      if (success) {
        return JSON.parse(JSON.parse(data.payload)); // FIXME
      } else {
        const { error } = data;
        throw new Error(`Error fetching createAssignment. ${error}`);
      }
    });
  };

  const generateAiQuestions = async () => {
    const response = await callCreateAssignmentApi();
    let receivedAiQuestions = response.questions.map((q) => ({
      ...new Question(QUESTION.OPEN_ENDED, undefined, taskType),
      ...{ content: q, aiQuestion: true, bot: { active: false, used: false } }
    }));

    setQuestions((questions) => {
      const filteredQuestions = questions.filter((q) => {
        const validQuestion =
          q.content.trim() !== "" ||
          (q.interaction_subtype === INTERACTION_SUBTYPES.MULTI_CHOICE &&
            !!q.options?.find((o) => o.trim() !== ""));
        return validQuestion && (!q.aiQuestion || !q.bot || q.bot?.used);
      });
      receivedAiQuestions = receivedAiQuestions.map((q, index) => {
        return {
          ...q,
          order: filteredQuestions.length + index + 1
        };
      });
      return [...filteredQuestions, ...receivedAiQuestions];
    });

    setBotConfigDirty(false);
    return receivedAiQuestions;
  };

  const generateAiAssignment = async (replace = false) => {
    userAPI.logAction({
      action_name: USER_ACTIONS.TASK_GENERATE_AI_ASSIGNMENT,
      payload: {
        task_id: task?.id || null,
        replace: replace
      }
    });

    setBotGenerating(true);
    if (replace)
      setQuestions((questions) => {
        return questions.map((q) => ({ ...q, bot: { active: false } }));
      });
    setAiQuestionsPlaceholder(3);
    await generateAiQuestions();
    setQuestions((questions) => {
      const aiQuestions = questions.filter((q) => q.aiQuestion);

      const newQuestionsIndex = aiQuestions.findIndex(
        (q) => q.bot && !q.bot.used
      );
      for (const [index, question] of aiQuestions.entries()) {
        const newQuestion =
          index >= newQuestionsIndex && index < newQuestionsIndex + 3;

        if (!question.bot) {
          question.bot = { active: true, used: true };
        }
        question.bot.active = newQuestion
          ? true
          : replace
            ? false
            : question.bot.active;
        question.bot.used = newQuestion ? true : question.bot.used;
      }
      return questions;
    });
    taskTitle === "" && setTaskTitle(text.name);
    const containerHeight = questionsContainerRef.current.offsetHeight;
    questionsContainerRef.current.style.minHeight = `${containerHeight}px`;
    setBotGenerating(false);
  };

  const regenerateAiQuestion = async (tempId) => {
    if (botConfigDirty) {
      setShowRegeneratingDialog(true);
      setRegeneratingDialogContent({ action: "regenerate", data: tempId });
      return;
    }

    regenerateSingleQuestion(tempId);
  };

  const addAiQuestion = async () => {
    if (botConfigDirty) {
      setShowRegeneratingDialog(true);
      setRegeneratingDialogContent({ action: "add" });
      return;
    }

    regenerateSingleQuestion();

    userAPI.logAction({
      action_name: USER_ACTIONS.TASK_GENERATE_AI_QUESTION,
      payload: {
        task_id: task?.id || null,
        new: true
      }
    });
  };

  const timeout = async (delay) => {
    return new Promise((res) => setTimeout(res, delay));
  };

  const regenerateSingleQuestion = async (tempId) => {
    if (!tempId) {
      setAiQuestionsPlaceholder(1);
      await timeout(1000);
    } else {
      setQuestions((questions) => {
        const oldQuestion = questions.find((q) => q.tempId === tempId);
        return questions.map((q) =>
          q.tempId === tempId ? { ...q, aiQuestionGenerating: true } : q
        );
      });
    }

    const aiQuestionsLeft =
      questions.filter((q) => q.aiQuestion && q.bot && !q.bot.used).length > 0;
    (!aiQuestionsLeft || botConfigDirty) && (await generateAiQuestions());
    setQuestions((questions) => {
      const newQuestionsIndex = questions.findIndex(
        (q) => q.aiQuestion && !(!q.bot || q.bot?.used)
      );

      if (tempId) {
        // regenerate existed question
        const oldQuestion = questions.find((q) => q.tempId === tempId);
        if (!oldQuestion.bot) {
          oldQuestion.bot = { active: true, used: true };
        }
        questions[newQuestionsIndex].bot.active = true;
        questions[newQuestionsIndex].bot.used = true;
        questions[newQuestionsIndex].order = oldQuestion.order;
        oldQuestion.bot.active = false;
      } else {
        // add new question
        for (const [index, question] of questions.entries()) {
          if (!question.aiQuestion) continue;
          const newQuestion =
            index >= newQuestionsIndex && index < newQuestionsIndex + 1;
          if (!question.bot) {
            question.bot = { active: true, used: true };
          }
          question.bot.active = newQuestion ? true : question.bot.active;
          question.bot.used = newQuestion ? true : question.bot.used;
        }
      }

      return [...fixQuestionsOrder(questions)];
    });
    const containerHeight = questionsContainerRef.current.offsetHeight;
    questionsContainerRef.current.style.minHeight = `${containerHeight}px`;
  };

  const renderRegeneratingDialog = () => {
    return (
      <Dialog
        open={showRegeneratingDialog}
        onClose={() => setShowRegeneratingDialog(false)}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm">
        <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
          {intl.formatMessage({
            id: "task.create.assignment.bot.regenerateQuestionDialogTitle",
            defaultMessage: "AI Input Updated"
          })}
        </DialogTitle>
        <DialogContent>
          <Typography>
            {intl.formatMessage({
              id: "task.create.assignment.bot.regenerateQuestionDialogContent",
              defaultMessage:
                "Do you want to add new questions to the list or replace the current questions?"
            })}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowRegeneratingDialog(false);
            }}
            sx={{ color: "#787877" }}>
            {intl.formatMessage({
              id: "general.cancel",
              defaultMessage: "Cancel"
            })}
          </Button>
          <Button
            onClick={() => {
              regenerateSingleQuestion(regeneratingDialogContent.data);
              setShowRegeneratingDialog(false);
            }}
            color="primary">
            {intl.formatMessage({
              id: "task.create.assignment.bot.regenerateQuestionsDialogGenerate",
              defaultMessage: "Generate"
            })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  //Render
  return (
    <>
      <FormControl
        variant="outlined"
        className={clsx(classes.taskTitle, classes.formRow)}>
        <TextField
          id="taskTitle"
          aria-label="task-title-input"
          autoFocus={!readOnly}
          disabled={readOnly}
          InputProps={{
            readOnly: readOnly
          }}
          label={intl.formatMessage({
            id: "task.create.assignment.assignmentTitle",
            defaultMessage: "Assignment title"
          })}
          className={clsx(classes.textField)}
          value={taskTitle}
          onChange={(e) => {
            setTaskTitle(e.target.value);
          }}
        />
      </FormControl>

      <FormControl variant="outlined" className={classes.formRow}>
        <TextField
          aria-label="task-description-input"
          id="taskDescription"
          readOnly={readOnly}
          InputProps={{
            readOnly: readOnly
          }}
          label={intl.formatMessage({
            id: "task.create.assignment.assignmentDescription",
            defaultMessage: "Assignment description (optional)"
          })}
          className={classes.textField}
          multiline={true}
          value={taskDescription}
          disabled={taskType === "peerReview" || readOnly}
          onChange={(e) => {
            setTaskDescription(e.target.value);
          }}
        />
      </FormControl>

      <Box className={clsx(classes.formRow)}>
        <Accordion
          expanded={botCardexpanded}
          onChange={handleBotCardExpansion}
          sx={{
            border: "1px solid rgba(0, 0, 0, 0.23)",
            borderRadius: "4px",
            boxShadow: 0,
            width: "100%"
          }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="ai-assistant-panel-title"
            sx={{ display: "flex", alignItems: "center" }}>
            <FormControlLabel
              control={
                <Switch
                  checked={botAssistant}
                  onChange={handleBotSwitch}
                  inputProps={{ "aria-label": "controlled" }}
                  sx={{
                    "& .MuiSwitch-track": { backgroundColor: "#757575" }
                  }}
                />
              }
              label={intl.formatMessage({
                id: "task.create.assignment.bot.title",
                defaultMessage: "AI Assistant"
              })}
              sx={{ marginRight: "50px" }}
              disabled={readOnly}
            />
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
                color: "#757575"
              }}>
              <AIIcon />
              {intl.formatMessage({
                id: "task.create.assignment.bot.craftWithAI",
                defaultMessage: "Craft your assignment effortlessly with AI"
              })}
            </Box>
          </AccordionSummary>
          <AccordionDetails
            sx={{ padding: readOnly ? "16px 40px 16px" : "16px 40px 32px" }}>
            <AssignmentAssistantCard
              readOnly={readOnly}
              selectedDifficulty={selectedDifficulty}
              setSelectedDifficulty={setSelectedDifficulty}
              readingFocus={readingFocus}
              setReadingFocus={setReadingFocus}
              selectedQuestionKind={selectedQuestionKind}
              setSelectedQuestionKind={setSelectedQuestionKind}
              generateAiAssignment={generateAiAssignment}
              botGenerating={botGenerating}
              aiQuestions={aiQuestions}
              botConfigDirty={botConfigDirty}
              visibleQuestions={visibleQuestions}
            />
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box ref={questionsContainerRef}>
        {visibleQuestions.map((question, index) => {
          return (
            <Box
              key={index}
              sx={{ marginBottom: "30px" }}
              ref={(el) => (questionsRef.current[index] = el)}>
              <AssignmentQuestion
                taskId={task?.id}
                text={text}
                readOnly={readOnly}
                taskType={taskType}
                questionIndex={index}
                {...question}
                onUpdate={!readOnly && updateQuestions}
                onAdd={!readOnly && addQuestion}
                onCopy={!readOnly && copyQuestion}
                onDelete={() => {
                  !readOnly && deleteQuestion(question.tempId);
                }}
                showType={taskType === TASK.TYPE.STANDARD}
                showPoints={taskType === TASK.TYPE.STANDARD}
                disabled={readOnly}
                regenerateAiQuestion={regenerateAiQuestion}
                questionRegenerating={questionRegenerating}
              />
            </Box>
          );
        })}
        {aiQuestionsPlaceholder > 0 && (
          <AiQuestionsPlaceholder
            count={aiQuestionsPlaceholder}
            taskType={taskType}
            startIndex={visibleQuestions.length}
          />
        )}
      </Box>
      {addQuestionRegenerating && (
        <Skeleton
          variant="rounded"
          height={235}
          sx={{ borderRadius: "12px", marginBottom: "30px", width: "100%" }}
        />
      )}

      {!readOnly && (
        <Box>
          <Button
            onClick={addQuestion}
            startIcon={<AddIcon />}
            disabled={readOnly}
            sx={{ marginRight: "16px" }}>
            {intl.formatMessage({
              id: "task.create.assignment.composeQuestion",
              defaultMessage: "Compose question"
            })}
          </Button>
          {botAssistant && (
            <Button
              onClick={addAiQuestion}
              startIcon={<AIIcon color="#1565c0" />}
              disabled={readOnly}>
              {intl.formatMessage({
                id: "task.create.assignment.bot.generateQuestion",
                defaultMessage: "Generate question"
              })}
            </Button>
          )}
        </Box>
      )}
      {showRegeneratingDialog && renderRegeneratingDialog()}

      {setActiveStep && (
        <NavigationButtons
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          publishTask={publishTask}
          saveTask={saveTask}
          validateForm={validateForm}
        />
      )}
    </>
  );
}
