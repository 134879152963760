import { add } from "date-fns";
import ePub from "epubjs";
import _ from "lodash";
import { userAPI } from "../../api";
import { INTERACTION_TYPES, appActions } from "../../consts";
import { httpCallables } from "../../firebase";
import { captureException } from "../../utils/errorHandlers";
import PdfCFI from "../../utils/pdf-cfi";

export function getHighlightColor(color, darkMode) {
  let colors = [
    {
      color: "#BEF3BF",
      text: "color.green",
      hlColorOld: "#BEF3BF",
      hlColor: "#BEF3BF",
      darkMode: "#BEF3BF"
    },
    {
      color: "#B9E7FF",
      text: "color.blue",
      hlColorOld: "#B9E7FF",
      hlColor: "#B9E7FF",
      darkMode: "#B9E7FF"
    },
    {
      color: "#ECC5FF",
      text: "color.pink",
      hlColorOld: "#ECC5FF",
      hlColor: "#ECC5FF",
      darkMode: "#ECC5FF"
    },
    {
      color: "#FF7F74",
      text: "color.red",
      hlColorOld: "#FF7F74",
      hlColor: "#FF7F74",
      darkMode: "#FF7F74"
    },
    {
      color: "#FFE690",
      text: "color.yellow",
      hlColorOld: "#F5BC21",
      hlColor: "#FFE690",
      darkMode: "#FFE690"
    }
  ];

  let relEntry = colors.filter(
    (c) =>
      c.color === color ||
      c.hlColor === color ||
      c.hlColorOld === color ||
      c.darkMode === color
  );
  if (relEntry && relEntry.length) {
    let entry = relEntry[0];
    if (darkMode && entry.darkMode) {
      return entry.darkMode;
    } else return entry.hlColor;
  }

  return color;
}

export function removeAllAnotationsOfType(rendition, type) {
  const annotations = rendition?.annotations?._annotations || {}; //TODO: this is a hack, once the packdge fix the .each method for looping over the anotations this will need to change

  for (const annotation in annotations) {
    try {
      rendition.annotations.remove(annotation, type);
    } catch (err) {
      console.log("err in remove annotation ", err);
    }
  }
}

// Clear the browser API selection
export function clearSelection(rendition) {
  if (!rendition) {
    //pdf?
    window.getSelection().empty();
  }
  let content = rendition && rendition.getContents()[0];
  let selection = content && content.window && content.window.getSelection();
  if (selection && selection.empty && clearSelection) {
    selection.empty();
  }
}

export function combinePartialCfisToCfi(a, b, { isPdf = false }) {
  if (isPdf) return combinePartialCfisToPdfCfi(a, b);
  else return combinePartialCfisToEpubCfi(a, b);
}

function combinePartialCfisToEpubCfi(a, b) {
  const EpubCFI = new ePub.CFI();

  const start = EpubCFI.parse(a),
    end = EpubCFI.parse(b);
  const cfi = {
    range: true,
    base: start.base,
    path: {
      steps: [],
      terminal: null
    },
    start: start.path,
    end: end.path
  };
  const len = cfi.start.steps.length;
  for (let i = 0; i < len; i++) {
    if (EpubCFI.equalStep(cfi.start.steps[i], cfi.end.steps[i])) {
      if (i === len - 1) {
        // Last step is equal, check terminals
        if (cfi.start.terminal === cfi.end.terminal) {
          // CFI's are equal
          cfi.path.steps.push(cfi.start.steps[i]);
          // Not a range
          cfi.range = false;
        }
      } else cfi.path.steps.push(cfi.start.steps[i]);
    } else break;
  }
  cfi.start.steps = cfi.start.steps.slice(cfi.path.steps.length);
  cfi.end.steps = cfi.end.steps.slice(cfi.path.steps.length);

  return (
    "epubcfi(" +
    EpubCFI.segmentString(cfi.base) +
    "!" +
    EpubCFI.segmentString(cfi.path) +
    "," +
    EpubCFI.segmentString(cfi.start) +
    "," +
    EpubCFI.segmentString(cfi.end) +
    ")"
  );
}

function combinePartialCfisToPdfCfi(a, b) {
  const reg = /([^/]*)$/;
  let startCfi = a.split(reg);
  let endCfi = b.split(reg);

  const startNode = a
    .split("/")
    .filter((a) => {
      return a != null;
    })
    .slice(-2)[0];
  const endNode = b
    .split("/")
    .filter((a) => {
      return a != null;
    })
    .slice(-2)[0];

  let newCfi;
  if (startNode == endNode)
    newCfi =
      startCfi[0].slice(0, -1) +
      ",/" +
      startCfi[1].slice(0, -1) +
      ",/" +
      endCfi[1];
  else {
    const commonPrefix = a.split(/\//).slice(0, -2).join("/");
    const startOffSet = a.split("/").slice(-2).join("/").replace(")", "");
    const endOffSet = b.split("/").slice(-2).join("/");
    newCfi = commonPrefix + ",/" + startOffSet + ",/" + endOffSet;
  }
  return newCfi;
}

function findOverlapAtEnd(a, b) {
  if (b.length === 2) {
    return "";
  }

  if (a.indexOf(b) >= 0) {
    return b;
  }

  if (a.endsWith(b)) {
    return b;
  }

  return findOverlapAtEnd(a, b.substring(0, b.length - 1));
}

export function getEpubContentInRange(rendition, start, end) {
  const splitCfi = start.split("/");
  const baseCfi =
    splitCfi[0] + "/" + splitCfi[1] + "/" + splitCfi[2] + "/" + splitCfi[3];
  const startCfi = start.replace(baseCfi, "");
  const endCfi = end.replace(baseCfi, "");
  const rangeCfi = [baseCfi, startCfi, endCfi].join(",");
  const range = rendition.getRange(rangeCfi);
  let text = range.toString();
  return text;
}

export function logLocationChangeEvent(
  text_id,
  text = "",
  course_id,
  user_id,
  start,
  end,
  format
) {
  try {
    const now = new Date();
    userAPI.log({
      user_id,
      course_id,
      action_name: appActions.ARTICLE_READING_EVENT,
      created_at: now,
      ttl: add(now, { months: 1 }),
      payload: {
        start,
        end,
        text_id,
        text,
        format
      }
    });
  } catch (err) {
    captureException(err);
  }
}

export function getLocationByEntity(obj) {
  const result = {};
  if ("location" in obj) {
    result.lastPage = obj.location || null;
    result.location = obj.location;
  } else if ("interaction_type" in obj && "pdfPosition" in obj) {
    result.lastPage = obj.pdfPosition[0].pageNumber;
    result.location = obj.pdfPosition[0].cfi;
    result.id = obj.id;
  } else if ("interaction_type" in obj && !("pdfPosition" in obj)) {
    result.lastPage = null;
    result.location = obj.pdfPosition[0].cfi;
  }
  return result;
}

export function mergeOverlappingHighlightsBySelection(
  existingHighlightsList,
  isPdf,
  newHighlight,
  rendition = null
) {
  const EpubCFI = new ePub.CFI();
  const PdfCfi = new PdfCFI();
  const CFIByFileType = isPdf ? PdfCfi : EpubCFI;
  const filteredHighlights = existingHighlightsList.filter(
    (el) => el.interaction_type === INTERACTION_TYPES.ANSWER
  );
  const selectedHighlight = splitElementToStartAndEnd(newHighlight);
  const sortedHl = sortHighlightsByCfi(
    populateSplittedHighlightToArray(filteredHighlights),
    CFIByFileType
  );
  let newCfi = newHighlight.cfi;
  let content = newHighlight.content;
  let overlappingHighlightsBySelection = [];
  let newPdfPosition = "";
  for (let i = 0; i < sortedHl.length; i += 2) {
    const curStartCfi = sortedHl[i];
    const curEndCfi = sortedHl[i + 1];

    const doesNewHlStartBeforeCurHlEnd =
      CFIByFileType.compare(curEndCfi.cfi, selectedHighlight[0].cfi) > 0;
    const doesNewHlEndAfterCurHlstart =
      CFIByFileType.compare(selectedHighlight[1].cfi, curStartCfi.cfi) > 0;

    if (doesNewHlStartBeforeCurHlEnd && doesNewHlEndAfterCurHlstart) {
      // If it enters here, we have overlapping highlights

      // Check if start is longer than original highlight, create new longer cfi
      if (
        CFIByFileType.compare(curStartCfi.cfi, selectedHighlight[0].cfi) < 0
      ) {
        selectedHighlight[0] = curStartCfi;
      }

      // Check if end is longer than original highlight, create new longer cfi
      if (CFIByFileType.compare(selectedHighlight[1].cfi, curEndCfi.cfi) <= 0) {
        selectedHighlight[1] = curEndCfi;
      }

      // Set new cfi based on start and end cfis
      newCfi = combinePartialCfisToCfi(
        selectedHighlight[0].cfi,
        selectedHighlight[1].cfi,
        { isPdf }
      );

      if (isPdf) {
        const overlappingString = findOverlapAtEnd(
          selectedHighlight[0].content,
          selectedHighlight[1].content
        );
        selectedHighlight[0].content = _.trimEnd(
          selectedHighlight[0].content,
          overlappingString
        );
        content = selectedHighlight[0].content.concat(
          selectedHighlight[1].content
        );
      } else {
        content = getEpubContentInRange(
          rendition,
          selectedHighlight[0].cfi,
          selectedHighlight[1].cfi
        );
      }

      // Add current highlight to the list of overlapping highlights to be deleted
      overlappingHighlightsBySelection.push(curStartCfi, curEndCfi);
    }
  }
  overlappingHighlightsBySelection = removeDuplicates(
    overlappingHighlightsBySelection
  );

  const returnObj = {
    newHighlight: {
      cfi: newCfi,
      content,
      isPdf: isPdf ? true : false
    },
    mergedHighlights: overlappingHighlightsBySelection
  };
  if (isPdf) {
    newPdfPosition = combinePdfPosition(
      selectedHighlight[0].pdfPosition[0],
      newCfi
    );
    returnObj.newHighlight.pdfPosition = [newPdfPosition];
  }
  return returnObj;
}

function splitElementToStartAndEnd(highlight) {
  let cfiParts = highlight.cfi.split(","); // cfiBase:  cfiParts[0]
  let startCfi = cfiParts[0] + cfiParts[1] + ")"; // start: ‘epubcfi(/6/4[chap01ref]!/4[body01]/10[para05]/2/1:1)’
  let endCfi = cfiParts[0] + cfiParts[2];

  return [
    {
      ...highlight,
      type: "start",
      cfi: startCfi,
      val: 1
    },
    { ...highlight, type: "end", cfi: endCfi, val: -1 }
  ];
}

function combinePdfPosition(pdfPosition, cfi) {
  return {
    ...pdfPosition,
    cfi: cfi
  };
}

function populateSplittedHighlightToArray(highlights) {
  let coll = [];
  highlights
    .filter(
      (h) => h && h.cfi && h.interaction_type !== INTERACTION_TYPES.SUGGESTION
    )
    .forEach((hl) => {
      coll = coll.concat(splitElementToStartAndEnd(hl));
    });
  return coll;
}

function sortHighlightsByCfi(highlightsArray, CFIByFileType) {
  return [...highlightsArray].sort(function (a, b) {
    let val = CFIByFileType.compare(a.cfi, b.cfi);
    if (val === 0) {
      if (a.val === b.val) return 0;
      return b.val < 0 ? 1 : -1;
    }
    return val;
  });
}

function removeDuplicates(arr) {
  const uniqueIds = new Set();

  return arr.reduce((result, obj) => {
    if (!uniqueIds.has(obj.id)) {
      uniqueIds.add(obj.id);
      result.push(obj);
    }
    return result;
  }, []);
}
export async function fetchAllUserHighlightsPerText(text_id) {
  try {
    let highlightInteractions = [];
    await httpCallables
      .interactionFunctions({
        text_id: Number(text_id),
        shouldBringAllTextInteraction: true,
        func_name: "readUserInteractionsPerText"
      })
      .then(({ data }) => {
        const { success } = data;
        if (success) {
          const { interactions } = JSON.parse(data.payload);
          interactions.forEach((interaction) => {
            if (interaction.cfi) highlightInteractions.push(interaction);
          });
        } else {
          let error = new Error(`READ_TEXT_INTERACTIONS_FAILED`);
          error.message = data.error;
          error.data = { text_id };
          throw error;
        }
      });
    return highlightInteractions;
  } catch (err) {
    captureException(err);
  }
}
