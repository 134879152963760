export const PaletteDark = {
  primary: {
    main: "#168FEE",
    light: "#90CAF9",
    dark: "#42A5F5",
    contrast: "#000000"
  },
  secondary: { main: "#5ec891", light: "#5ec891" },
  warning: { main: "#FFA726", contrast: "#000000" },

  background: {
    default: "#121212",
    sidePanel: "#1e1e1e",
    paper: "#1c2d39",
    readerBar: "#66BB69",
    studentView: "#1c2d39"
  },
  card: {
    background: {
      disabled: "#2e2e2e"
    },
    text: {
      disabled: "#7b7b7b"
    }
  },

  text: {
    primary: "#FFFFFF",
    blue: "#BCD9F1",
    green: "#467fcf",
    disabled: "#EEEEEE",
    secondary: "#000000 60%"
  },

  blue: {
    main: "#168fee"
  },
  lightText: {
    main: "#000000",
    muted: "#FFFFFF8F"
  },
  grey: {
    main: "#1c2d39 70%"
  },
  snackbar: {
    background: "rgba(250, 250, 250, 1)",
    text: "rgba(0, 0, 0, 0.6)",
    button: "rgba(0, 0, 0, 0.87)"
  },
  border: {
    main: "rgba(0, 0, 0, 0.6)",
    secondary: "#7E7E7E"
  },
  pieChart: {
    SubmissionPending_no: "#495761",
    SubmissionMissed_total: "#EF5350",
    SubmissionMissed_incomplete: "#EF5350",
    SubmissionMissed: "#EF5350",
    SubmissionMissed_no: "#EF5350",
    SubmissionPending_total: "#495761",
    SubmissionPending_complete: "#495761",
    SubmissionPending: "#495761",
    SubmissionLate_complete: "#C8E6C9",
    SubmissionLate_incomplete: "#FF9800",
    SubmissionOnTime_complete: "#388E3C",
    SubmissionOnTime_incomplete: "#81C784",
    REVIEW_SESSION_total: "#90CAF9",
    HIGHLIGHT_SESSION_total: "#D7EEFF",
    ANSWER_SESSION_total: "#42A5F5"
  }
};
