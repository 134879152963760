import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { firebaseFunctions, httpCallables } from "../../firebase";
import {
  IconButton,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Menu,
  MenuItem,
  Typography
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { addSnackbarItem } from "../../redux/snackbarSlice";
import { v4 as uuid } from "uuid";
import { useIntl } from "react-intl";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    width: "600px"
  },
  dialogTitle: {},
  dialog: {},
  btns: {},
  cancelBtn: {},
  cancelBtnLtr: {},
  yesBtn: {},
  yesBtnLtr: {}
}));

export default function RemoveUserMenu(props) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const classes = useStyles();
  const getAllUsers = firebaseFunctions.httpsCallable(
    "adminFunctions-getAllCourseUsers"
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [showDialog, setShowDialog] = useState(false);
  const [dialogAction, setDialogAction] = useState("");
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);

  const handleAction = (action) => {
    handleClose();
    setShowDialog(true);
    setDialogAction(action);
  };

  const handleDeleteTransientUser = async () => {
    setAnchorEl(null);
    const response = await httpCallables.courseUserFunctions({
      func_name: "deleteTransientUser",
      deletedUserId: props.row.course_user
    });
    if (response.data.success) {
      dispatch(
        addSnackbarItem({
          intlId: "admin.userDeletedTransient",
          intlDefaultMessage: "User deleted transiently",
          id: uuid()
        })
      );
    }
  };

  const handleDeleteUser = async () => {
    setAnchorEl(null);
    const response = await httpCallables.courseUserFunctions({
      func_name: "deleteUser",
      deletedUserId: props.row.course_user
    });
    if (response.data.success) {
      getAllUsers({ course: props.courseId }).then((response) => {
        props.setUsers(response.data);
        dispatch(
          addSnackbarItem({
            intlId: "admin.userDeleted",
            intlDefaultMessage: "User deleted",
            id: uuid()
          })
        );
      });
    }
  };

  const makeUserActive = async () => {
    setAnchorEl(null);
    const response = await httpCallables.courseUserFunctions({
      func_name: "makeUserActive",
      userId: props.row.course_user,
      courseId: props.courseId
    });
    if (response.data.success) {
      if (response.data.success) {
        getAllUsers({ course: props.courseId }).then((response) => {
          props.setUsers(response.data);
          dispatch(
            addSnackbarItem({
              intlId: "admin.userReaddToCourse",
              intlDefaultMessage: "User is now active in course",
              id: uuid()
            })
          );
        });
      }
    }
  };

  const handleRemoveUserFromCourse = async () => {
    setAnchorEl(null);
    const response = await httpCallables.courseUserFunctions({
      func_name: "removeUserFromCourse",
      userId: props.row.course_user,
      courseId: props.courseId
    });
    if (response.data.success) {
      if (response.data.success) {
        getAllUsers({ course: props.courseId }).then((response) => {
          props.setUsers(response.data);
          dispatch(
            addSnackbarItem({
              intlId: "admin.userRemovedFromCourse",
              intlDefaultMessage: "User removed from course",
              id: uuid()
            })
          );
        });
      }
    }
  };

  const resetMentor = async () => {
    setAnchorEl(null);
    httpCallables
      .resetMentorForCourseUser({
        course_id: props.courseId,
        user_uid: props.row.course_user
      })
      .then((response) => {
        dispatch(
          addSnackbarItem({
            intlId: "admin.resetMentor",
            intlDefaultMessage: "Reset Conversation for User",
            id: uuid()
          })
        );
      });
  };

  const handleDeleteUserFromCourse = async () => {
    setAnchorEl(null);
    const response = await httpCallables.courseUserFunctions({
      func_name: "deleteUserFromCourse",
      deletedUserId: props.row.course_user,
      courseId: props.courseId
    });
    if (response.data.success) {
      if (response.data.success) {
        getAllUsers({ course: props.courseId }).then((response) => {
          props.setUsers(response.data);
          dispatch(
            addSnackbarItem({
              intlId: "admin.userRemovedFromCourse",
              intlDefaultMessage: "User Deleted from course",
              id: uuid()
            })
          );
        });
      }
    }
  };

  const dialogOprerations = {
    deleteTransientUser: handleDeleteTransientUser,
    deleteUser: handleDeleteUser,
    removeUserFromCourse: handleRemoveUserFromCourse,
    deleteUserFromCourse: handleDeleteUserFromCourse,
    resetMentor: resetMentor,
    makeUserActive: makeUserActive
  };

  return (
    <>
      <IconButton color="primary" onClick={handleClick} variant="contained">
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        <MenuItem onClick={() => handleAction("deleteTransientUser")}>
          {intl.formatMessage({
            id: `admin.removeUser.menu.deleteTransientUser`,
            defaultMessage: "Delete user transiently"
          })}
        </MenuItem>
        <MenuItem onClick={() => handleAction("deleteUser")}>
          {intl.formatMessage({
            id: `admin.removeUser.menu.deleteUser`,
            defaultMessage: "Delete user"
          })}
        </MenuItem>
        {props.row.status == "ACTIVE" && (
          <MenuItem onClick={() => handleAction("removeUserFromCourse")}>
            {intl.formatMessage({
              id: `admin.removeUser.menu.removeUserFromCourse`,
              defaultMessage: "Remove user from course"
            })}
          </MenuItem>
        )}
        {props.row.status == "INACTIVE" && (
          <MenuItem onClick={() => handleAction("makeUserActive")}>
            {intl.formatMessage({
              id: `admin.removeUser.menu.makeUserActive`,
              defaultMessage: "Activate user in course"
            })}
          </MenuItem>
        )}

        <MenuItem onClick={() => handleAction("deleteUserFromCourse")}>
          {intl.formatMessage({
            id: `admin.removeUser.menu.deleteUserFromCourse`,
            defaultMessage: "Delete user from course"
          })}
        </MenuItem>
        <MenuItem onClick={() => handleAction("resetMentor")}>
          {intl.formatMessage({
            id: `admin.removeUser.menu.resetMentor`,
            defaultMessage: "Reset mentor"
          })}
        </MenuItem>
      </Menu>
      {showDialog && (
        <Dialog
          open={showDialog}
          onClose={() => {
            setShowDialog(false);
          }}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
          fullWidth>
          <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
            {intl.formatMessage({
              id: "admin.removeUser.dialogTitle",
              defaultMessage: "Delete confirmation"
            })}
          </DialogTitle>
          <DialogContent className={clsx(classes.dialog)}>
            <Typography>
              {intl.formatMessage({
                id: `admin.removeUser.dialogContent.${dialogAction}`,
                defaultMessage: "Are you sure you want to do this?"
              })}
            </Typography>
            <FormGroup>
              <FormControlLabel
                required
                control={
                  <Checkbox
                    checked={deleteConfirmed}
                    onChange={(event) =>
                      setDeleteConfirmed(event.target.checked)
                    }
                  />
                }
                label={intl.formatMessage({
                  id: `admin.removeUser.checkbox`,
                  defaultMessage: "I'm sure"
                })}
              />
            </FormGroup>
          </DialogContent>
          <DialogActions className={classes.btns}>
            <Button
              className={clsx(classes.cancelBtn, classes.cancelBtnLtr)}
              onClick={() => {
                setShowDialog(false);
                setDeleteConfirmed(false);
              }}>
              {intl.formatMessage({
                id: `general.cancel`,
                defaultMessage: "Cancel"
              })}
            </Button>
            <Button
              className={clsx(classes.yesBtn, classes.yesBtnLtr)}
              onClick={() => {
                dialogOprerations[dialogAction]();
                setShowDialog(false);
                setDeleteConfirmed(false);
              }}
              color="primary"
              disabled={!deleteConfirmed}>
              {intl.formatMessage({
                id: `general.confirm`,
                defaultMessage: "Confirm"
              })}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
